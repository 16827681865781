.product-sub-container {
  border-top: 1px solid #bcb9b94f;
}

.product-col {
  border-left: 1px solid #bcb9b94f;
}

.filter-item {
  border-bottom: 1px solid #bcb9b94f;
  padding: 15px;
}

.filter-sortby-items {
  background: none;
  border: none;
  padding-left: 0;
  display: flex;
  accent-color: #1e9acb;
}
.filter-sortby-items:hover {
  background: none;
}

.filter-sortby-items::selection {
  background: none;
}

.filter-sortby-items > input {
  height: 20px;
  width: 20px;
}

.filter-stock-items {
  background: none;
  border: none;
  padding-left: 0;
  display: flex;
  accent-color: #1e9acb;
}
.filter-stock-items:hover {
  background: none;
}

.filter-stock-items::selection {
  background: none;
}

.filter-stock-items > input {
  height: 20px;
  width: 20px;
}

.multi-range-slider-container {
  max-width: 600px;
  margin: auto;
}

.multi-range-slider .bar-inner {
  box-shadow: none !important;
}

.multi-range-slider .bar {
  height: 4px;
}

.multi-range-slider .bar {
  height: 4px;
}

.multi-range-slider .thumb-left {
  background-color: var(--blue);
}
.multi-range-slider .bar-left {
  padding: 0 !important;
}

.multi-range-slider .bar-inner {
  border: none !important;
}

.multi-range-slider .thumb .caption *{
  background-color:  #0191D0 !important;
}

@media only screen and (max-width: 767px) {
  /* Styles to hide the div */
  .product-in-page-filters {
    display: none;
  }

  .mobile-filter-btn{
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  /* Styles to hide the div */
  .product-in-page-filters {
    display: block;
  }

  .mobile-filter-btn{
    display: none;
  }
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: #d8d8d8;
    color: black;
    border-radius: 1px;
    font-weight: bold;
}

.nav-item > .nav-link {
  color: black;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  // background-color: #e5e7e9;
  background-clip: padding-box;
  border: 1px solid rgb(188, 188, 188);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: #e5e7e9;
}