@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700;800&display=swap');

.whiteSpacePre {
    white-space: pre;
}

.whiteSpacePreLine {
    white-space: pre-line;
}

.mobile-menu-open div div.default-product-info-snackbar {
    height: inherit;
    overflow: visible;
    opacity: 1;
}

.text-red {
    color: var(--red);
}

.btn-check:checked+.btn-primary, .btn-check:active+.btn-primary, .btn-primary:active, .btn-primary.active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--blue) !important;
    border-color: var(--blue) !important;
}

.mr-5 {
    margin-right: 5px;
}

.dropdown-item.active, .dropdown-item:active {
    color: var(--white) !important;
    text-decoration: none;
    background-color: var(--title) !important;
}

.top-border {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.ml-1 {
    margin-left: 10px !important;
}

.tp100 {
    padding-top: 100px !important;
}

.btn-group .btn-otp.btn-otp-cancel {
    background-color: var(--white);
    color: var(--title);
    margin-right: 15px;
}

.btn-group .btn-otp.btn-otp-cancel:hover {
    background-color: var(--title);
    color: var(--white);
}

.choice-cat-lst ul {
    overflow: auto;
    white-space: nowrap;
    width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */

.choice-cat-lst ul::-webkit-scrollbar {
    display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */

.choice-cat-lst ul {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* .choice-cat-lst ul::-webkit-scrollbar-button { */
/* display: none; */
/* width: 10px; */
/* background-color: var(--orange); */
/* background-color: currentColor; */
/* } */
/* .choice-cat-lst ul li:hover .chbx-bx{
    margin-top: 0;
    transform:translateY(20px);
} */
.choice-bx.on-page .chbx-bx, .choice-bx:hover {
    margin: 0;
}

.choice-bx h3 {
    padding: 75px 20px;
}

/* CSS BY Renish */
.swal2-confirm.swal2-styled {
    background-color: var(--red);
}

.swal2-confirm.swal2-styled:hover {
    background-color: var(--title) !important;
}

.swal2-cancel.swal2-styled:hover {
    background-color: var(--blue) !important;
}

.owl-theme .owl-nav [class*='owl-']:hover {
    background: none !important;
}

button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
}

.selectBoxCss div {
    border: none;
    /* background: none; */
}

.marginAuto {
    margin: auto !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.m-0 {
    margin: 0;
}

/* .pl-0 { 
    padding-left:  0px !important;
} */
/* Remove Up Down Arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.otp-div input {
    width: 87% !important;
}

.pl-0 {
    padding-left: 0 !important;
}

a {
    text-decoration: none !important;
}

.owl-theme .owl-nav {
    margin-top: 0px !important;
}

.right-0 {
    right: 0 !important;
}

.cp {
    cursor: pointer;
}

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}

a:hover,
a:focus,
a:active {
    text-decoration: none;
}

ol, ul {
    margin: 0;
    padding: 0;
}

img {
    display: block;
}

body {
    font-family: 'Hind';
    font-size: 14px;
    font-weight: 400;
    color: var(--white);
    background: var(--lightblue) !important;
}

button {
    outline: none !important;
}

a {
    transition: .4s linear;
}

/*::-webkit-scrollbar {
  display: none;
}*/
.container {
    max-width: 1550px !important;
    width: 1550px;
}

/* **** Color Variables **** */
:root {
    --lightblue: #E4E7E9;
    --blue: #0191D0;
    --white: var(--white)fff;
    --darkblue: #112B46;
    --title: #131939;
    --orange: #F28500;
    --green: #0EB66B;
    --purple: #6A5ACD;
    --pink: #FF64B2;
    --black: #000000;
    --bor-color: rgba(19, 25, 57, 0.10);
    --red: #FB624B;
    --darkblue: #3E4B5A;
    --lightyellow: #F7B666;
}

/* **** End Color Variables **** */


/* **** Header **** */
header {
    padding: 20px 30px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 120;
    background: #e4e7e9;
}

.header-row {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.head-left {
    width: 50%;
}

.head-right {
    width: 50%;
    ;
}

.head-left h1 {
    margin: 0;
    font-size: 52px;
    font-weight: bold;
    color: var(--title);
    font-family: 'Poppins';
}

.head-right {
    text-align: right;
}

.head-right ul {
    margin: 0;
}

.head-right li {
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle;
}

.head-right li:last-child {
    margin: 0;
}

.head-right li a.log-btn {
    padding: 8px 15px;
    background: var(--title);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--white);
    border-radius: 30px;
    display: inline-block;
}

.head-right li a.log-btn img {
    display: inline-block;
    margin-right: 10px;
    width: 13px;
    vertical-align: middle;
}

.head-right li a.log-btn:hover {
    background: var(--blue);
}

.head-right li a.user-img img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    object-fit: contain;
}

.head-left a.user-img img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    object-fit: contain;
}

/* **** End Header **** */

/* **** Banner Slider **** */
.banner-sliders {
    width: 100%;
    overflow: hidden;
    padding: 15px 0;
}

.banner-sliders .item {
    margin: 0;
}

.slider-bx {
    position: relative;
}

.slider-bx img {
    width: 100%;
    border-radius: 10px;
}

.simmer-box {
    animation-duration: 2.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 1200px 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    z-index: 8;
}

/* 
-webkit-@keyframes shimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
} */

@keyframes shimmer {
    0% {
        background-position: -1200px 0;
    }

    100% {
        background-position: 1200px 0;
    }
}

.slide-img {
    padding: 0 0px;
    position: relative;
}

.banner-sliders .slick-slide div {
    height: 170px;
}

.banner-sliders .atc-box {
    padding: 0 60px;
}

.banner-sliders .atc-box hr {
    margin-bottom: 0;
}

/* **** End Banner Slider **** */

/* **** Products Listing **** */
.products-wrp {
    padding: 0 0px 0 0px;
    overflow: hidden;
    width: 100%;
}

.product-lst {
    padding: 0 0;
}

.titlebx h2 {
    font-size: 28px;
    font-weight: 700;
    color: var(--title);
    margin: 0 0 15px;
    padding-left: 60px;
}

.slider-arrivalbx {
    padding: 10px 35px 10px 0;
    width: 100%;
}

.products-wrp .slick-list {
    padding: 0 25% 0 0;
}

figure {
    background: var(--white);
    margin: 0 0 15px;
    padding: 15px;
    border-radius: 15px;
    display: block;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
    position: relative;
}

figure:after {
    position: absolute;
    content: '';
    background: var(--white);
    border-radius: 15px;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
    width: 100%;
    height: 95%;
    top: 50%;
    right: -16px;
    z-index: -1;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
}


figure img {
    width: 100%;
    height: 300px;
    object-fit: contain;
    border-radius: 10px;
    margin: 0 auto 10px;
}

figure.downstep {
    margin: 70px 0 15px;
}

.mini-bx img {
    border-radius: 10px;
    width: 100%;
    /*height: 350px;
    object-fit: cover;*/
}

figcaption {
    position: relative;
}

figcaption h2 {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block !important;
    background-color: #fff;
    /* padding-right: 55px !important; */
}

figcaption h2 b {
    /* font-size: 22px; */
    font-weight: 700;
    color: var(--title) !important;
    margin: 0;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    font-size: 16px;
}

figcaption h2 a:hover {
    color: var(--white);
}

figcaption h2 .link-prd:hover {
    color: var(--blue);
}

.category-wrp figcaption h2 {
    /* min-height: 80px; */
    display: flex;
    align-items: center;
}

figcaption p {
    font-size: 18px;
    font-weight: 400;
    color: var(--title);
    margin: 0 0 15px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block !important;
    background-color: #fff;
}

figcaption .log-btn {
    padding: 12px 18px;
    background: var(--title);
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--white) !important;
    /*Added Important By Renish*/
    line-height: 16px;
    border-radius: 30px;
    display: table;
    box-shadow: 0 3px 6px rgba(149, 181, 209, 0.3);
}

figcaption .log-btn img {
    max-width: 15px;
    height: auto;
    width: auto;
    margin: 0;
    margin-right: 5px;
    border-radius: 0;
    display: inline-block;
}

figcaption .log-btn i {
    padding-right: 5px;
}

figcaption .log-btn:hover {
    background: var(--blue);
}

figcaption .heart {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    font-size: 16px;
    color: var(--white) !important;
    /*Added Important By Renish*/
    background: var(--blue);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: 0;
    box-shadow: 0 3px 6px rgba(107, 163, 213, 0.6);
}

figcaption.active .log-btn {
    background-color: var(--green);
}

figcaption.active .log-btn:hover {
    background: var(--title);
}

figcaption.active .heart {
    background-color: var(--white);
    color: var(--red) !important;
    /*Added Important By Renish*/
    box-shadow: 0 3px 6px rgba(128, 128, 128, 0.6);
}

/* figcaption.active .heart:hover{
    background: var(--title);
    color: var(--white); 
} */
/* figcaption .heart:hover{
    background: var(--title); 
   background: var(--title); 
    background: var(--title); 
} */

.atc-box {
    padding: 0 0px;
}

.atc-box hr {
    border-top: 1px solid var(--bor-color);
}

.products-wrp .atc-box {
    padding: 0 60px 0;
}

.view-allpro {
    text-align: right;
    width: 100%;
}

.view-btn {
    padding: 12px 15px 12px 20px;
    background: var(--orange);
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--white);
    line-height: 16px;
    border-radius: 30px;
    display: inline-block;
    box-shadow: 0 3px 6px rgba(149, 181, 209, 0.3);
    margin: 25px 0;
    position: relative;
    z-index: 5;
}

.view-btn:hover {
    background: var(--title);
}

.view-allpro .simmer-box {
    border-radius: 10px;
}

.view-btn i {
    padding-left: 10px;
}

.view-btn:hover {
    background: var(--title) !important;
    color: var(--white) !important;
}


.pro-lst-blks .owl-nav {
    width: 150px;
    position: absolute;
    top: -45px;
    right: 75px;
}

.pro-lst-blks .owl-prev, .pro-lst-blks .owl-next {
    position: absolute;
    top: 0%;
    right: 0;
    margin-top: 0px;
}

.pro-lst-blks .owl-next {
    right: 50px;
    display: block;
}

.pro-lst-blks .owl-prev:before, .pro-lst-blks .owl-next:before {
    font-family: "Font Awesome 5 Pro";
    content: "\f105";
    width: 40px;
    height: 40px;
    background: var(--blue);
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    color: var(--white);
    display: block;
    opacity: 1;
    z-index: 120;
    cursor: pointer;
    -webkit-transition: 0.4s linear;
    -moz-transition: 0.4s linear;
    -ms-transition: 0.4s linear;
    transition: 0.4s linear;
}

.pro-lst-blks .owl-prev:hover:before, .pro-lst-blks .owl-next:hover:before {
    color: var(--white);
    background: var(--title);
}

.pro-lst-blks .owl-next:before {
    content: "\f104";
    right: 0;
    left: auto;
}

.category-wrp {
    margin: 0 0;
}

.category-wrp .view-btn {
    background: var(--green);
}

.category-wrp figcaption h2 {
    padding: 10px 0;
}

.women-wrp {
    margin: 0 0;
    padding: 0 60px 0 60px;
}

.women-wrp .atc-box {
    padding: 0 15px 0 0;
}

.women-box {
    width: 95%;
    position: relative;
}

.women-wrp .view-btn {
    background: var(--purple);
    border-radius: 10px;
}

.women-wrp .titlebx h2 {
    display: flex;
    align-items: center;
    padding-left: 0;
}

.women-wrp .titlebx h2 span {
    background: var(--purple);
    padding: 5px 20px 3px;
    font-size: 16px;
    border-radius: 30px;
    color: var(--white);
    margin-left: 15px;
    font-weight: 400;
    box-shadow: 0 3px 9px rgba(209, 149, 203, 0.4);
}

.men-wrp {
    margin: 0 0;
    padding: 0 60px;
}

.men-wrp .titlebx h2 {
    display: flex;
    align-items: center;
    padding-left: 0;
}

.men-wrp .titlebx h2 span {
    background: var(--pink);
    padding: 5px 20px 3px;
    font-size: 16px;
    border-radius: 30px;
    color: var(--white);
    margin-left: 15px;
    font-weight: 400;
    box-shadow: 0 3px 9px rgba(209, 149, 203, 0.4);
}

.men-wrp .view-btn {
    background: var(--pink);
    border-radius: 10px;
}

.arrival-slider .owl-carousel {
    right: -60px;
}

/* **** End Products Listing **** */

/* **** Footer **** */
footer {
    padding: 0 0 170px;
    position: relative;
}

footer .footer-block h3 {
    font-size: 22px;
    display: table;
    margin: 0 auto 20px;
    font-weight: 500;
    color: var(--black);
    opacity: 0.5;
}

footer .footer-block h2 {
    font-size: 35px;
    display: table;
    margin: 0 auto 5px;
    font-weight: 700;
    color: var(--black);
}

footer .footer-block p {
    font-size: 20px;
    display: table;
    margin: 0 auto 20px;
    font-weight: 500;
    color: var(--black);
}

footer .footer-block .footer-ic ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

footer .footer-block .footer-ic ul li {
    margin-right: 20px;
}

footer .footer-block .footer-ic ul li:last-child {
    margin-right: 0;
}

footer .footer-block .footer-ic ul li a img {
    max-width: 50px;
}

/* **** End Footer **** */

/* **** Fixed Menu **** */
.bottom-nav {
    position: fixed;
    background: var(--white);
    bottom: 50px;
    padding: 10px;
    border-radius: 40px;
    box-shadow: 0 3px 9px rgba(37, 0, 0, 0.2);
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    z-index: 51;
}

.bottom-nav ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottom-nav ul li {
    margin-right: 0px;
}

.bottom-nav ul li a i {
    font-size: 18px;
}

.bottom-nav ul li a {
    text-align: center;
    display: table;
    margin: 0 auto;
    padding: 0 25px;
    font-size: 16px;
    font-weight: 600;
    color: var(--title);
    transition: all 0.3s linear;
    position: relative;
}

.bottom-nav ul li a img {
    max-width: 100%;
    height: 25px;
    margin: 0 auto;
    display: table;
}

.bottom-nav ul li a .badge {
    position: absolute;
    top: -5px;
    right: 25px;
    color: var(--white);
    border-radius: 100%;
    background-color: var(--red);
    font-size: 12px;
    padding: 0;
    font-weight: 500;
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottom-nav ul li.active a {
    height: 60px;
    width: 60px;
    background: var(--blue);
    border-radius: 100%;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottom-nav ul li a span {
    display: table;
    text-align: center;
    margin: 0 auto;
}

.pro-lst-blks ul li .titlebx {
    display: none;
}

.bottom-nav ul li.active a span {
    display: none;
}

.bottom-nav ul li.active a img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
    width: 40px;
    max-width: 40px;
    height: auto;
}

/* **** End Fixed Menu **** */

/* **** Cart Popup **** */
/* .modal{
    
} */
.modal .show {
    padding-right: 0 !important;
}

.modal-dialog {
    max-width: 550px !important;
    top: 50%;
    -webkit-transform: translate(0, -50%) !important;
    transform: translate(0, -50%) !important;
    margin: 0 auto;
}

.modal-content {
    background: var(--white);
    box-shadow: 0 0 23px rgba(0, 0, 0, 0.12);
    border-radius: 15px !important;
    border: none !important;
}

.cart-head {
    padding: 20px 20px;
    width: 100%;
    display: flex;
    align-items: center;
}

.cart-proimg img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: 6px;
}

.cart-dt {
    padding: 0 18px;
}

.cart-dt h4 {
    font-size: 26px;
    font-weight: bold;
    color: var(--title);
    margin: 0px;
    margin-top: 2.5rem;
}

.cart-dt p {
    font-size: 16px;
    font-weight: normal;
    color: #373E64;
    margin: 0;
}

.inner-cart-lst {
    height: 347px;
    overflow: auto;
}

.cart-editbx {
    padding: 0 0 0 0;
}

.cart-editbx ul,
.cart-hed ul {
    width: 100%;
    overflow: hidden;
}

.cart-hed ul li {
    padding: 15px 20px 15px 40px;
    background: #112B46;
    float: left;
    width: 30%;
    border: none;
    font-size: 16px;
    font-weight: bold;
    color: var(--white);
    min-height: 54px;
    text-transform: uppercase;
}

.cart-hed ul li.quty {
    width: 23.33%;
}

.cart-hed ul li.prc {
    width: 23.33%;
}

.cart-hed ul li.epclone {
    width: 23.33%;
}

.cart-editbx ul li {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    color: var(--title);
    border: none;
    float: left;
    min-height: 54px;
    vertical-align: middle;
    width: 30%;
}

.cart-editbx ul li.quty {
    width: 30%;
}

.cart-editbx ul li.prc {
    width: 30%;
}

.cart-editbx ul li.epclone {
    width: 10%;
    text-align: right;
    padding: 10px 0;
}

.cart-editbx ul li h3 {
    font-weight: bold;
    font-size: 22px;
    margin: 0;
}

.cart-editbx ul li .form-control {
    padding: 5px 0;
    height: auto !important;
    border: none;
    border-bottom: 2px solid rgba(19, 25, 57, 0.10);
    border-radius: 0;
    box-shadow: none;
    font-size: 18px;
    font-weight: 600;
    color: #0191D0;
    width: 100px;
}

.cart-editbx ul li .form-control:focus {
    border-bottom: 2px solid #0191D0;
}

.pricbx i {
    margin-right: 5px;
}

a.copyic i {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 40px;
    background: var(--white);
    color: rgba(19, 25, 57, 0.74);
    font-size: 16px;
    text-align: center;
    transition: .4s linear;
}

a.copyic i:hover {
    background: rgba(19, 25, 57, 1);
    color: var(--white);
}

.boron {
    border-top: 1px solid rgba(19, 25, 57, 0.10);
}

.cart-actionbar {
    border-top: 1px solid rgba(19, 25, 57, 0.10);
    padding: 15px 15px;
    text-align: right;
}

.cart-actionbar .btn-main {
    border-radius: 30px;
    border: none;
    padding: 10px 25px;
    background: #0191D0;
    font-weight: bold;
    color: var(--white);
    font-size: 16px;
    min-width: 130px;
    border: 1px solid #0191D0;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.cart-actionbar .btn-main:hover {
    background: var(--title);
    color: var(--white);
    border-color: var(--title);
}

.cart-actionbar .btn-main.calbtn {
    margin-right: 15px;
    border: 1px solid rgba(19, 25, 57, 0.26);
    color: rgba(19, 25, 57, 1);
    background: var(--white);
    transition: all 0.3s linear;
}

.cart-actionbar .btn-main.calbtn:hover {
    background: var(--title);
    color: var(--white)
}

a.view-btn.mobilbtn {
    display: none;
}

.modal {
    background: transparent;
}

.background-blurbx {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.01);
    /* (plus shape's fill blended on top as a separate layer with 77% opacity) */
}

.background-blurbx-force {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.01);
    z-index: 55;
    /* (plus shape's fill blended on top as a separate layer with 77% opacity) */
}


.copy-popup-block {
    padding: 35px;
    background: var(--white);
    border-radius: 15px;
    box-shadow: 0 0 23px rgba(0, 0, 0, 0.10);
}

.copy-popup-block h2 {
    font-size: 32px;
    font-weight: 600;
    color: #112134;
    margin: 0 0 40px;
    text-align: center;
}

.copy-popup-block p {
    font-size: 22px;
    font-weight: 500;
    color: #112134;
    opacity: 0.77;
    width: 75%;
    text-align: center;
    margin: 0 auto 30px;
}

.copy-popup-block .cart-actionbar {
    text-align: center;
    border: none;
    padding: 0;
}

/* **** End Cart Popup **** */

/* **** Scroll Bar Css Starts **** */
.mCSB_scrollTools .mCSB_draggerRail {
    width: 5px;
    border-radius: 30px;
    background: #979797;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: #005185;
    display: none;
}

.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background: #005185;
}

/* **** Scroll Bar Css Ends **** */

/* **** Mobile Cart **** */
.mobilecart-aside {
    position: fixed !important;
    bottom: -120%;
    left: 0;
    right: 0;
    z-index: 999;
    border-radius: 30px 30px 0 0 !important;
    transition: .4s linear !important;
}

figcaption .mobolbtn {
    display: none;
}

.modal-content.mobilecart-aside.mobile-menu-open {
    bottom: 0;
}

.mobilecart-aside .cart-head {
    padding: 20px 15px;
}

.mobilecart-aside .cart-hed {
    background: var(--title);
    background-color: #ffffff
}

.mobilecart-aside .cart-proimg img {
    height: 55px;
    width: 55px;
}

.mobilecart-aside .cart-dt h4 {
    font-size: 20px;
}

.mobilecart-aside .cart-dt {
    padding: 0 15px;
}

.mobilecart-aside .cart-hed ul li {
    padding: 10px;
    font-size: 14px;
    min-height: 41px;
}

.mobilecart-aside .mCSB_inside>.mCSB_container {
    margin-right: 0;
}

.mobilecart-aside .cart-editbx ul li {
    padding: 10px;
}

.mobilecart-aside .cart-editbx ul li {
    width: 25%;
}

.mobilecart-aside .cart-editbx ul li.quty {
    width: 25%;
}

.mobilecart-aside .cart-editbx ul li.prc {
    width: 35%;
}

.mobilecart-aside .cart-editbx ul li.epclone {
    width: 15%;
}

.mobilecart-aside .cart-editbx ul li .form-control {
    width: 100%;
}

.mobilecart-aside .cart-actionbar {
    border-top: none;
}

.back-blur-fx {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--white);
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.1);
    /* (plus shape's fill blended on top as a separate layer with 77% opacity) */
    opacity: 0;
    visibility: hidden;
    transition: .4s linear;
    z-index: 110;
}

.back-blur-fx.add-clbx.mobile-menu-open {
    opacity: 1;
    visibility: visible;
}

/* **** End Mobile Cart **** */

/* **** Search Page **** */
.search-wrp {
    padding: 130px 30px 0;
    width: 100%;
}

.search-bx {
    position: relative;
    width: 30%;
    margin: 0 auto 45px;
}

.search-bx .form-control {
    padding: 10px 25px 10px 45px;
    background: var(--white);
    border-radius: 30px;
    border: none;
    height: auto !important;
    box-shadow: none;
    color: rgba(17, 43, 70, 0.87);
    font-size: 18px;
    font-weight: normal;
}

.search-bx .search-ic {
    background: transparent;
    border: none;
    font-size: 18px;
    color: var(--title);
    position: absolute;
    top: 12px;
    left: 15px;
}

.search-bx button {
    position: absolute;
    top: 50%;
    right: 5px;
    height: 40px;
    width: 40px;
    transform: translate(0, -50%);
    border: none;
    background: var(--blue);
    border-radius: 100%;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
}

.search-bx button:hover {
    background: var(--title);
    color: var(--white);
}

.product-listing-wrp {
    padding: 30px 30px;
}

.product-listing-wrp h2 {
    font-size: 28px;
    font-weight: 700;
    color: var(--title);
    margin: 0 0 25px;
}

.product-listing-wrp figcaption h2 {
    font-size: 22px;
    font-weight: 700;
    color: var(--title);
    margin: 0;
    padding: 10px 0;
}

.product-listing-wrp .view-btn {
    background: transparent;
    color: var(--title);
    font-weight: 700;
    padding: 12px 25px;
    font-size: 15px;
    border: 1px solid var(--title);
    box-shadow: none;
    float: none;
    margin: 35px auto;
    display: table;
}

.product-listing-wrp figure {
    margin: 0 0 30px;
}

/* **** End Search Page **** */

/* **** Products Page **** */
.products-head {
    margin: 0 0 35px;
}

.products-head h2 {
    margin: 0;
    position: relative;
}

.products-head .row {
    align-items: center;
}

.filter-bx button {
    width: 36px;
    height: 36px;
    background: var(--darkblue);
    border-radius: 50%;
    line-height: normal;
    text-align: center;
    font-size: 16px;
    color: var(--white);
    border: none;
    box-shadow: none;
    outline: none !important;
    float: right;
    position: relative;
    overflow: hidden;
}

.filter-bx button:hover {
    background: var(--title);
    color: var(--white);
}


.dropdown-toggle::after {
    display: none !important;
}

.filter-bx .dropdown-menu {
    background: var(--blue);
    border-radius: 15px;
    box-shadow: "0 3px 6px rgb(0 0 0 / 16%)";
    border: none;
    padding: 0;
    top: 100% !important;
    right: 0;
    width: 130px;
    left: auto !important;
    transform: translate(0px, 0px) !important;
    margin: 50px 0 0;
    z-index: 1;
}

.filter-bx .dropdown-menu a {
    padding: 10px 10px;
    font-size: 16px;
    font-weight: 500;
    color: var(--white);
    border-bottom: 1px solid rgba(255, 255, 255, 0.33) !important;
    display: block;
}

.filter-bx .dropdown-menu a:last-child {
    border-bottom: none;
}

.filter-bx .dropdown-menu a:hover {
    background: transparent;
    color: rgba(255, 255, 255, 0.80);
}

.product-listing-wrp figcaption h2 {
    position: relative;
    padding: 10px 0px 10px 0;
}

.product-listing-wrp figcaption h2 a:hover {
    color: var(--blue);
}

.product-listing-wrp figcaption .heart {
    bottom: 3px;
}

.pro-pribxs h5 {
    font-size: 26px;
    font-weight: bold;
    color: var(--title);
    margin: 0;
    padding: 10px 0 0;
    display: inline-block;
}

.pro-pribxs .log-btn {
    display: inline-block;
    float: right;
}

.pro-pribxs .mobolbtn {
    display: none;
}

.pro-dt-hed .mobolbtn {
    display: none !important;
}

.addQty.mobolbtn {
    display: none !important;
}

.bucketEditIcon.mobolbtn {
    display: none !important;
}

.loadingbtn {
    padding: 10px 25px;
    border-radius: 30px;
    background: var(--orange);
    font-size: 18px;
    font-weight: 600;
    color: var(--white);
    display: table;
    margin: 25px auto;
}

.loadingbtn i {
    margin-right: 10px;
}

.loadingbtn:hover {
    color: var(--white);
}

.not-found-block {
    padding: 50px 0;
}

.found-box img {
    margin: 0 auto 35px;
}

.found-box h4 {
    font-size: 30px;
    font-weight: bold;
    color: var(--black);
    text-align: center;
    margin: 0 0px;
}

.found-box p {
    font-size: 20px;
    font-weight: normal;
    color: var(--black);
    margin: 0;
    text-align: center;
}

/* **** End Products Page **** */

/* **** Category Choice Page **** */
.category-choice-wrp {
    padding: 0px 30px 0;
}

.choice-cat-lst {
    padding: 15px 0 0;
}

.choice-bx {
    border-radius: 15px;
    padding: 0 5px;
    min-height: 230px;
    /*250px*/
    max-height: 230px;
    /*250px*/
    transition: .4s linear;
    display: inline-block;
    min-width: 144px;
    max-width: 144px;
}

.chbx-bx {
    position: relative;
    overflow: hidden;
    border-radius: 16px;
}

.chbx-bx {
    transition: .4s linear;
}

.choice-bx.on-page .chbx-bx,
.choice-bx:hover .chbx-bx {
    margin-top: 20px;
    background: var(--blue);
}

.choice-img {
    position: relative;
    height: "150px";
    background-color: var(--white)
}

.choice-img img {
    width: 100%;
    /* border-radius: 15px; */
    object-fit: contain;
    height: 150px;
    object-fit: "contain";
}

.choice-img:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: var(--title);
    opacity: 0;
    top: 0;
    left: 0;
    transition: .4s linear;
    border-radius: 15px;
}

.choice-bx.on-page .chbx-bx .choice-img:before,
.choice-bx:hover .chbx-bx .choice-img:before {
    opacity: 0;
}

.chbx-bx {
    /* background: var(--title); */
    border-radius: 15px;
    overflow: hidden;
}

.choice-bx h4 {
    padding: 10px 10px !important;
    text-align: center;
    background: var(--title);
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: var(--white);
    transition: .4s linear;
    overflow: hidden;
    text-overflow: ellipsis;
}

.choice-bx.on-page .chbx-bx h4,
.choice-bx:hover .chbx-bx h4 {
    background: var(--blue);
}

.choice-lastbix .chbx-bx {
    min-height: 190px;
    /* display: flex; */
    /* align-items: center; */
    justify-content: center;
    background: var(--title);
}

.choice-bx h3 {
    padding: 10px 15px;
    text-align: center;
    margin: 0;
    margin-top: 55%;
    font-size: 18px;
    font-weight: 600;
    color: var(--white);
    transition: .4s linear;
}

/* **** End Category Choice Page **** */

/* **** Product Detail Page **** */
.products-details-wrp {
    padding: 40px 0;
}

.products-details-wrp .row {
    align-items: center;
}

.products-details-slider {
    position: relative;
}

.pro-dt-img {
    position: relative;
}

.pro-dt-img img {
    border-radius: 40px;
    width: 100%;
    object-fit: contain !important;
    height: auto;
}

.pro-dt-img a i {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    text-align: center;
    box-shadow: 0 3px 6px rgba(40, 79, 106, 0.16);
    font-size: 20px;
    color: var(--white);
    background: var(--blue);
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 5;
    transition: .4s linear;
}

.pro-dt-img a i:hover {
    background: var(--title);
}

.products-details-slider .slick-dots {
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.products-details-slider .slick-dots li {
    display: inline-block;
    margin-right: 10px;
    height: auto;
    width: auto;
    vertical-align: middle;
}

.products-details-slider .slick-dots li button {
    background: var(--title);
    outline: none !important;
    opacity: 0.17;
    border: none;
    width: 10px;
    height: 10px;
    vertical-align: middle;
    border-radius: 50%;
    font-size: 0;
    color: transparent;
    cursor: pointer;
    -webkit-transition: 0.4s linear;
    -moz-transition: 0.4s linear;
    -ms-transition: 0.4s linear;
    transition: 0.4s linear;
}

.products-details-slider .slick-dots li.slick-active button {
    background: var(--blue);
    opacity: 1;
}

.products-details-slider .owl-dots {
    width: 90%;
    position: absolute;
    left: 50%;
    bottom: -40px;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.products-details-slider .owl-dot {
    display: inline-block;
    margin-right: 15px;
    position: relative;
}

.products-details-slider .owl-dot:last-child {
    margin-right: 0;
}

.products-details-slider .owl-dot span {
    width: 13px !important;
    height: 13px !important;
    border-radius: 50% !important;
    display: inline-block !important;
    background: var(--title) !important;
    opacity: 0.17;
}

.products-details-slider .owl-dot.active span {
    background: var(--blue) !important;
    opacity: 1;
}

.pro-details-box {
    position: relative;
    background: var(--white);
    padding: 30px;
    box-shadow: 0 6px 19px rgba(0, 0, 0, 0.16);
    border-radius: 40px;
    width: 90%;
    float: right;
}

.pro-details-box:before {
    position: absolute;
    content: '';
    background: var(--white);
    padding: 30px;
    border-radius: 40px;
    width: 90%;
    top: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: -1;
}

.pro-dt-hed {
    padding: 15px 0;
    border-bottom: 1px solid var(--bor-color);
    position: relative;
}

.quick-act-pro {
    position: absolute;
    top: -60px;
    right: 0px;
}

.quick-act-pro ul li {
    display: inline-block;
    margin-right: 10px;
}

.quick-act-pro ul li:last-child {
    margin: 0;
}

.quick-act-pro ul li a {
    position: relative;
    display: block;
}

.quick-act-pro ul li a i {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(158, 158, 158, 0.45);
    background: var(--blue);
    color: var(--white);
    text-align: center;
    display: block;
    font-size: 16px;
    transition: all 0.3s linear;
}

.quick-act-pro ul li a i:hover {
    background: var(--title);
    color: var(--white);
}

.quick-act-pro ul li a span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: var(--red);
    line-height: 16px;
    color: var(--white);
    display: block;
    text-align: center;
    font-weight: 700;
    font-size: 10px;
    position: absolute;
    top: 0;
    right: 0;
}

.pro-dt-hed h3 {
    font-size: 34px;
    font-weight: 600;
    color: var(--title);
    margin: 0 0 10px;
}

.pro-dt-hed h4 {
    font-size: 42px;
    font-weight: bold;
    color: var(--title);
    margin: 0 0;
    padding: 8px 0 0;
    line-height: 45px;
    vertical-align: middle;
    display: inline-block;
}

.pro-dt-hed .log-btn {
    float: right;
    background: var(--title);
    padding: 12px 25px;
    font-size: 18px;
    font-weight: 600;
    color: var(--white);
    cursor: pointer;
    line-height: 28px;
    border-radius: 30px;
    vertical-align: middle;
    display: inline-block;
}

.pro-dt-hed .log-btn.active {
    background: var(--green);
}

.pro-dt-hed .log-btn.active:hover {
    background: var(--title);
}

.quick-act-pro .heart.active {
    background-color: var(--white);
    color: var(--red) !important;
    /*Added Important By Renish*/
    box-shadow: 0 3px 6px rgba(128, 128, 128, 0.6);
}

.quick-act-pro .heart.active:hover {
    background: var(--title);
    color: var(--white) !important;
    /*Added Important By Renish*/
}

.pro-dt-hed .log-btn i {
    margin-right: 5px;
    vertical-align: middle;
}

.pro-dt-hed .log-btn:hover {
    background: var(--blue);
}

.pro-lst-bx li {
    display: block;
    padding: 10px 0;
    font-size: 20px;
    font-weight: bold;
    color: var(--title);
    text-transform: uppercase;
}

.pro-lst-bx li span {
    float: right;
    font-weight: normal;
    text-transform: capitalize;
}

.note-bx h4 {
    margin: 0 0 10px;
    color: var(--title);
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.note-bx p {
    font-size: 18px;
    font-weight: normal;
    color: var(--title);
    margin: 0;
    line-height: 26px;
}

i.heart.fas.fa-heart.heartbx {
    color: var(--red);
    background: var(--white);
}

.pro-details-box:before {
    display: none;
    width: 94%;
    border-radius: 50px;
}

.pro-details-box {
    padding: 0;
    overflow: hidden;
}

.pro-details-box.product-dt-box {
    padding: 30px 45px;
    overflow: visible;
}

.pro-details-box.product-dt-box:before {
    display: block;
}

.pro-details-box .cart-hed ul li {
    padding: 15px 30px;
}

.epclone {
    text-align: right;
}

.pro-details-box .mCSB_inside>.mCSB_container {
    margin-right: 0;
}

/* **** End Product Detail Page **** */




/* **** Order Page ***** */
.order-product-wrp {
    padding: 15px 0;
}

.wishlist {
    border: 1px solid rgba(17, 43, 70, 0.1);
    border-left: none;
    border-right: none;
}

/*.order-product-wrp .wishlist .titlebx h2{
    display: flex;
    align-items: center;
    float: left;
    margin: 0;
}
.order-product-wrp .wishlist .titlebx h2 span {
    background: var(--orange);
    padding: 5px 20px 5px;
    line-height: 20px;
    font-size: 16px;
    border-radius: 30px;
    color: var(--white);
    margin-left: 18px;
    font-weight: 400;
    box-shadow: 0 3px 9px rgb(209 149 203 / 40%);
}
.order-product-wrp .wishlist button{
    float: right;
    outline: none !important;
    box-shadow: none !important;
    padding: 6px 20px;
    width: 64px;
    height: 40px;
    background: var(--darkblue);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--white);
    border-radius: 30px;
    position: relative;
}
.order-product-wrp button:hover{
    background-color: var(--orange);
    color:var(--white);
}*/
.wishlist-accordian-header {
    padding: 0rem !important;
    background-color: transparent !important;
    border-bottom: none !important;
    /* margin-bottom: -2% !important; */
}

.accordion .card {
    background: transparent;
    border: none !important;
    box-shadow: none;
    outline: none;
}

.accordion .card .card-head h3 {
    background-size: 20px;
    cursor: pointer;
    font-size: 28px;
    font-weight: 700;
    color: var(--title);
    margin: 0 0 40px;
    padding-left: 0px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 25px 0;
}

.accordion .card .card-head h3 span {
    background: var(--orange);
    padding: 5px 20px 5px;
    line-height: 20px;
    font-size: 16px;
    border-radius: 30px;
    color: var(--white);
    margin-left: 18px;
    font-weight: 400;
    box-shadow: "0 3px 9px rgb(209 149 203 / 40%)";
}

.accordion .card .card-head h3:before {
    position: absolute;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    content: "\f078";
    width: 64px;
    height: 40px;
    background: var(--title);
    text-transform: uppercase;
    font-weight: 600;
    color: var(--white);
    border-radius: 30px;
    top: 25px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.accordion .card .card-head h3.collapsed:before {
    content: "\f077";
}

.accordion .card .card-body {
    padding: 0;
    margin: 15px 0 0;
}

div#Wishlist {
    margin: 25px 0 0;
}


.order-product-wrp .order-block {
    background-color: var(--white);
    border: 15px;
    min-height: 100px;
    display: block;
    border-radius: 15px;
    position: relative;
    margin: 0 0 30px;
    overflow: hidden;
    position: relative;
}

.order-product-wrp .order-box a {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--red);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--white) !important;
    border-radius: 30px;
    position: absolute;
    bottom: 0;
    left: 15px;
    box-shadow: "0 3px 6px rgb(251 98 75 / 30%)";
    transition: all 0.3s linear;
}

.order-product-wrp .order-box a:hover {
    background-color: var(--title);
    color: var(--white)
}

.order-product-wrp .order-block .row {
    display: flex;
    align-items: center;
}

.order-product-wrp .order-box {
    padding: 15px;
    display: flex;
    align-items: center;
}

.order-product-wrp .order-box .order-dt {
    padding-left: 15px;
}

.order-product-wrp .order-box img {
    max-width: 100%;
    height: 75px;
    width: 75px;
    border-radius: 10px;
    object-fit: contain;
}

.order-product-wrp .order-box h2 {
    font-size: 22px;
    font-weight: 700;
    color: var(--title);
    margin: 0;
}

.order-product-wrp .order-box p {
    font-size: 14px;
    font-weight: 400;
    color: var(--title);
    margin: 0 0 0;
}

.order-product-wrp .log-btn {
    padding: 10px 20px;
    background: var(--title);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--white);
    line-height: 16px;
    border-radius: 30px;
    display: table;
    box-shadow: "0 3px 6px rgb(149 181 209 / 30%)";
}

.order-product-wrp .log-btn:hover {
    background-color: var(--blue);
    color: var(--white)
}

.order-product-wrp .buket-wrp {
    padding: 30px 0 230px;
}

.order-product-wrp .buket-wrp .titlebx h2 {
    display: flex;
    align-items: center;
    float: left;
    margin: 0;
    padding: 0;
    font-size: 28px;
}

.order-product-wrp .buket-wrp .titlebx h2 span {
    background: var(--orange);
    padding: 5px 20px 5px;
    line-height: 20px;
    font-size: 16px;
    border-radius: 30px;
    color: var(--white);
    margin-left: 18px;
    font-weight: 400;
    box-shadow: "0 3px 9px rgb(209 149 203 / 40%)";
}

.buket-bix-header .row {
    display: flex;
    align-items: center;
}

.order-product-wrp .buket-wrp .buket-box {
    background-color: var(--white);
    border-radius: 15px;
    min-height: 200px;
    margin: 30px 0 0;
}

.order-product-wrp .buket-wrp .buket-box .buket-bix-header {
    padding: 15px;
}

.order-product-wrp .buket-wrp .buket-box .buket-info {
    display: flex;
    align-items: center;
}

.tbl-body ul li img,
.order-product-wrp .buket-wrp .buket-box .buket-info img {
    height: 75px;
    width: 75px;
    border-radius: 10px;
    object-fit: contain;
    margin-right: 15px;
}

.order-product-wrp .buket-wrp .buket-box .buket-info h2 {
    font-size: 20px;
    font-weight: 700;
    color: var(--title);
    margin: 0;
}

.order-product-wrp .buket-wrp .buket-box .buket-ic ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.order-product-wrp .buket-wrp .buket-box .buket-ic ul li {
    margin-right: 15px;
}

.order-product-wrp .buket-wrp .buket-box .buket-ic ul li:last-child {
    margin: 0;
}

.order-product-wrp .buket-wrp .buket-box .buket-ic ul li a {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    color: var(--darkblue);
    background: rgba(0, 0, 0, 0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.dot {
    position: relative;
}

.dot:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    background: var(--lightyellow);
    box-shadow: "0 3px 9px rgb(209 149 203 / 40%)";
    top: -2px;
    right: -2px;
    border: 2px solid var(--white);
    border-radius: 100%;
}

.order-product-wrp .buket-wrp .buket-box .buket-ic ul li:last-child a {
    background-color: #FEDFDB;
    color: var(--red)
}

.order-product-wrp .buket-wrp .buket-box .buket-ic ul li a:hover {
    background: var(--title);
    color: var(--white);
}

.order-product-wrp .buket-wrp .tbl-header {
    background-color: rgba(0, 85, 122, 0.2);
}

.order-product-wrp .buket-wrp .tbl-header ul {
    display: flex;
    align-items: center;
}

.order-product-wrp .buket-wrp .tbl-header ul li {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 85, 122, 0.75);
    font-weight: 600;
    border: none;
    float: left;
    width: 25%;
    padding: 10px 10px;
}

.order-product-wrp .buket-wrp .tbl-body h3 {
    font-size: 20px;
    font-weight: 700;
    color: #4B5766;
    margin: 15px 0 0 15px;
    text-transform: capitalize;
}

.order-product-wrp .buket-wrp .tbl-body ul {
    display: flex;
    align-items: flex-end;
}

.order-product-wrp .buket-wrp .tbl-body ul li:first-child {
    font-weight: 600;
}

.order-product-wrp .buket-wrp .tbl-body ul li span {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 85, 122, 0.75);
    font-weight: 600;
    margin: 0 0 10px;
}

.order-product-wrp .buket-wrp .tbl-body ul li {
    font-size: 20px;
    font-weight: 400;
    color: #4B5766;
    border: none;
    float: left;
    width: 25%;
    padding: 10px 15px !important;
}

.order-product-wrp .buket-wrp .tbl-body ul li:last-child {
    text-align: right;
}

.order-product-wrp .buket-wrp .tbl-body ul li:last-child i {
    padding-left: 0;
    text-align: right;
    /* color: var(--orange) */
}

.total-box {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px 15px;
}

.total-box h3 {
    font-size: 20px;
    font-weight: 700;
    color: var(--title);
    margin: 0;
    text-transform: capitalize;
}



.place-fix {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--white);
    padding: 20px 0;
    z-index: 9;
}

.place-fix .row {
    display: flex;
    align-items: center;
}

.place-fix .form-group label {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    margin: 0;
}

.place-fix .form-group .form-control {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(17, 33, 52, 0.1);
    padding: 0 0 10px;
    font-size: 18px;
    font-weight: 700;
    color: var(--title);
    margin: 0;
    resize: none;
    outline: none;
    box-shadow: none;
}

.total-bx {
    padding: 0 0 0 15px;
}

.total-bx ul {
    margin: 0;
}

.place-fix ul {
    display: flex;
    align-items: center;
}

.place-fix ul li {
    display: inline-block;
    font-size: 22px;
    font-weight: 500;
    color: var(--title);
    margin: 0 10px 0 0;
    width: 30%;
}

.place-fix ul li:nth-child(2) {
    width: 30%;
}

.place-fix ul li:last-child {
    margin: 0;
    width: 40%;
}

.place-fix ul li span {
    font-weight: 700;
    display: table;
}

.place-fix a {
    padding: 15px 40px;
    background: var(--blue);
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--white) !important;
    line-height: 16px;
    border-radius: 30px;
    cursor: pointer;
    display: table;
    box-shadow: "0 3px 6px rgb(149 181 209 / 30%)";
}

.place-fix a:hover {
    background: var(--title);
    color: var(--white);
}

.left-brd {
    position: relative;
    padding: 0 35px 0 15px;
}

.left-brd:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 2px;
    background-color: rgba(17, 33, 52, 0.1);
    right: 0;
    top: 0;
}

.place-fix .last-group {
    margin: 0;
}

.cart-not-found-bx {
    padding: 100px 0;
    text-align: center;
}

.cart-not-found-bx img {
    margin: 0 auto 35px;
}

.cart-not-found-bx h3 {
    font-size: 32px;
    font-weight: bold;
    color: var(--black);
    margin: 0 0 0px;
}

.cart-not-found-bx p {
    font-size: 20px;
    font-weight: normal;
    color: var(--black);
    margin: 0 0 0;
}

.delete-popup .modal-content,
#add-text-popup .modal-content {
    border-radius: 15px;
}

#add-text-popup .modal-dialog {
    max-width: 450px;
}

.delete-popup .modal-dialog {
    max-width: 480px;
}

.add-text-block {
    padding: 35px;
}

.add-text-block h3 {
    font-size: 28px;
    font-weight: 600;
    color: var(--title);
    margin: 0 0 15px;
}

.add-text-block .form-group {
    margin: 0 0 25px;
}

.add-text-block .form-control {
    padding: 10px 0;
    height: auto;
    background: var(--white);
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #E3E5E8;
    box-shadow: none;
    font-size: 16px;
    font-weight: 500;
    color: var(--title);
}

.add-text-block .cart-actionbar {
    border-top: none;
    text-align: center;
    padding: 0;
}

.add-text-block img {
    margin: 0 auto 25px;
    width: 130px;
}

.add-text-block h4 {
    font-size: 24px;
    font-weight: 600;
    color: var(--title);
    text-align: center;
    margin: 0 0 25px;
}

.trash-btns .btn-main {
    background: var(--red);
    border-color: var(--red);
}

.mobile-verifybx h4 {
    margin: 0;
}

.add-text-block p {
    font-size: 16px;
    font-weight: normal;
    color: #4B5766;
    margin: 0 0 40px;
    text-align: center;
}

.mobile-num-bx {
    width: 460px;
    margin: 0 auto;
}

.mobile-num-bx .form-control {
    padding: 10px 0;
    height: auto;
    background: var(--white);
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #E3E5E8;
    box-shadow: none !important;
    font-size: 16px;
    font-weight: 500;
    color: var(--title);
    text-align: center;
}

.mobile-num-bx h6 {
    font-size: 16px;
    text-align: center;
    margin: 15px 0 25px;
    color: var(--red);
}

.mobile-num-bx h6 i {
    margin-right: 5px;
}

.btn-otp {
    padding: 10px 15px;
    background: var(--title);
    width: 185px;
    text-align: center;
    border-radius: 30px;
    margin: 0 auto;
    font-size: 18px;
    font-weight: bold;
    color: var(--white);
    box-shadow: 0 3px 6px rgba(17, 33, 52, 0.16);
    border: none;
    display: table;
}

.btn-otp:hover {
    background: var(--blue);
    color: var(--white);
}

.otp-popup div {
    max-width: 650px !important;
}

.back-step i {
    width: 50px;
    height: 50px;
    background: var(--orange);
    color: var(--white);
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    font-size: 24px;
    transition: .4s linear;
}

.back-step i:hover {
    background: var(--title);
    color: var(--white);
}

.otp-head {
    display: flex;
    align-items: center;
}

.otp-wrp {
    padding: 15px 15px 30px;
}

.count-down-bx {
    margin-left: auto;
}

#timer {
    font-size: 30px;
    font-weight: 600;
    color: var(--title);
}

.otp-wrp h4 {
    margin: 0;
}

.otp-wrp p {
    margin: 0 0 5px;
}

.otp-wrp h3 {
    font-size: 18px;
    font-weight: 600;
    color: var(--orange);
    margin: 0 0 25px;
    text-align: center;
}

.otp-wrp h5 {
    font-size: 18px;
    font-weight: 500;
    color: #4B5766;
    text-align: center;
    margin: 0 0 10px;
}

.digit-group {
    text-align: center;
}

.digit-group input {
    width: 40px;
    height: 40px;
    background-color: var(--white);
    border: none;
    line-height: 40px;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    color: white;
    margin: 0 5px;
    border-bottom: 1px solid var(--bor-color);
    outline: none !important;
    color: var(--title);
}

.digit-group .splitter {
    padding: 0 5px;
    color: white;
    font-size: 24px;
}

.prompt {
    margin-bottom: 20px;
    font-size: 20px;
    color: white;
}

.otp-wrp .mobile-num-bx {
    width: 350px;
}

.otp-wrp .cart-actionbar .btn-main {
    text-transform: uppercase;
}

.otp-wrp .trash-btns .btn-main.calbtn {
    background: var(--white);
    color: var(--title);
    border-color: var(--bor-color);
}

.otp-wrp .trash-btns .btn-main {
    background: var(--orange);
    border-color: var(--orange);
    padding: 10px 45px;
}

.otp-wrp .trash-btns .btn-main:hover {
    background: var(--title);
    color: var(--white);
    border-color: var(--title);
}

.address-popup div {
    max-width: 750px !important;
}

.address-wrp h3 {
    font-size: 26px;
    font-weight: 600;
    color: var(--title);
    margin: 0 0 25px;
}

.address-wrp .form-group {
    margin: 0 0 25px;
}

.address-wrp .form-group label {
    display: block;
    font-size: 18px;
    font-weight: normal;
    color: var(--title);
    margin: 0 0 10px;
}

.address-wrp .form-group .form-control {
    padding: 10px 20px;
    background: rgba(17, 33, 52, 0.06);
    border-radius: 30px;
    border: 1px solid transparent;
    box-shadow: none;
    font-size: 15px;
    font-weight: normal;
    color: var(--title);
    height: auto;
}

.address-wrp .form-group select.form-control {
    display: block;
    margin: 0 0;
    -webkit-appearance: none;
    /* background-image: url('../images/down.svg');  */
    /* Remove Above By Renish */
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center right 15px;
}

.address-wrp .error .form-control {
    border-color: var(--red);
    background: #FEDFDB;
}

.alter-msg-bx h6.error-msg {
    margin: 0;
    font-size: 18px;
    color: var(--red);
    font-weight: normal;
}

.inputError {
    font-size: 15px;
    color: var(--red);
    font-weight: normal;
}

.alter-msg-bx h6.error-msg i {
    margin-right: 5px;
}

.address-wrp .btn-main {
    padding: 10px 40px;
    border-radius: 30px;
    background: var(--blue);
    color: var(--white);
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    float: right;
    min-width: 150px;
    text-align: center;
    display: inline-block;
}

.address-wrp .btn-main:hover {
    background: var(--title);
    color: var(--white);
}

.add-row .row {
    align-items: center;
}

.add-row .row .btn-group {
    text-align: right;
    width: 100%;
    justify-content: flex-end;
}

.add-row .row .btn-group .btn-main {
    float: none;
    border: 1px solid var(--bor-color);
    cursor: pointer;
    padding: 10px 15px;
}

.add-row .row .btn-group .btn-main.canbtn {
    background: var(--white);
    color: var(--title);
    margin-right: 10px;
}

.add-row .row .btn-group .btn-main.canbtn:hover {
    background: var(--title);
    color: var(--white);
}

.order-placed-wrp {
    background: #32BA7C;
    border-radius: 30px !important;
}

.order-placed-wrp h4 {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    color: var(--white);
    margin: 0 0px;
}

.order-placed-wrp p {
    font-size: 18px;
    font-weight: normal;
    color: var(--white);
    text-align: center;
    margin: 0 0 0px;
}

.order-placed-wrp .btn-main {
    padding: 10px 40px;
    border-radius: 30px;
    background: var(--title);
    color: var(--white);
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    margin: 25px auto;
    min-width: 150px;
    text-align: center;
    display: table;
}

.order-placed-wrp .btn-main:hover {
    background: var(--blue);
    color: var(--white);
}

.download-app h6 {
    font-size: 18px;
    font-weight: 600;
    color: var(--white);
    text-align: center;
    margin: 0 0 15px;
}

.download-app img {
    margin: 0 auto;
    width: 120px;
}

/* **** End Order Page **** */

/* **** 404 & 500 Page **** */
.page-notfound {
    width: 100%;
    height: 100vh;
    position: relative;
}

.center-pager {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.error-txt {
    text-align: center;
    margin: -180px auto 0;
    width: 60%;
    padding: 0 0 0 32px;
}

.center-pager img {
    margin: 0 auto;
}

.error-txt h3 {
    font-size: 38px;
    font-weight: bold;
    color: var(--black);
    text-align: center;
    margin: 0 0 5px;
}

.error-txt p {
    font-size: 18px;
    font-weight: normal;
    color: var(--black);
    text-align: center;
    margin: 0 0 50px;
}

.error-txt .btn-main {
    padding: 12px 60px;
    background: #FB624B;
    border-radius: 30px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-size: 16px;
    font-weight: bold;
    color: var(--white);
    display: table;
    margin: 0 auto;
    text-transform: uppercase;
}

.refrewrp .btn-main {
    background: #0191D0;
}

.refrewrp .btn-main:hover,
.error-txt .btn-main:hover {
    background: var(--title);
    color: var(--white);
}

/* **** End 404 & 500 Page **** */


.nav-new {
    position: fixed;
    width: 100%;
    padding: 15px 30px;
    transition: .4s linear;
    top: 0;
    background: var(--white);
    left: 0;
    border-radius: 0;
    box-shadow: "0px 1px 7px rgb(0 0 0 / 20%)";
    z-index: 51;
}

/*.address-wrp .btn-main{
    min-width: 180px;
}*/
.btn_back {
    background: var(--white);
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    box-shadow: 0 0 9px rgba(128, 128, 128, 0.2);
    font-size: 20px;
    color: var(--title);
    margin-right: 20px;
    transition: all 0.3s linear;
}

.btn_back:hover {
    background: var(--title);
    color: var(--white)
}

.head-left {
    display: flex;
    align-items: center;
}




.buket-grid {
    float: left;
    padding: 0 15px;
    width: 50%;
}

/*.level1 {
  grid-row-end: span 2;
}
.level2 {
  grid-row-end: span 2;
}
.level3 {
  grid-row-end: span 2;
}
.level4 {
  grid-row-end: span 2;
}
.level5 {
  grid-row-end: span 2;
}*/

.buket-wrp .buket-gallery {
    /* display: flex; */
    flex-flow: column wrap;
    align-items: center;
    margin: 30px 0 0;
}

.buket-wrp .buket-grid-block {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    -moz-column-gap: 1em;
    -webkit-column-gap: 1em;
    column-gap: 1em;
}

.buket-wrp .item {
    max-width: 100%;
    position: relative;
    display: inline-block;
    margin: 0 0 1em;
    width: 100%;
}

.buket-wrp .item {
    border-radius: 0;
    padding: 5px;
}

.buket-wrp .item img {
    max-width: 100%;
}

.order-product-wrp .buket-wrp .buket-box {
    margin: 0;
}

.choice-wrp-cat figcaption h2 {
    min-height: auto !important;
}

a.modal-mobile {
    display: none;
}

#mobile-search {
    display: none;
}

#mobile-search-dnone {
    display: block;
}

.order-product-wrp .plase-mobile-box {
    display: none;
}

.order-product-wrp .plase-mobile-box .buket-box {
    padding: 15px 15px;
    min-height: auto;
}

.plase-mobile-box .form-group label {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    margin: 0;
}

.plase-mobile-box .form-group .form-control {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(17, 33, 52, 0.1);
    padding: 0 0 10px;
    font-size: 18px;
    font-weight: 700;
    color: var(--title);
    margin: 0;
    resize: none;
    outline: none;
    box-shadow: none;
}

.plase-mobile-box .form-group.last-group {
    margin: 0;
}

a.btn-otp-side {
    padding: 10px 15px;
    background: var(--title);
    width: 150px;
    text-align: center;
    border-radius: 30px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 600;
    color: var(--white);
    box-shadow: "0 3px 6px rgb(17 33 52 / 16%)";
    border: none;
    display: table;
}


.verification-aside {
    position: fixed;
    bottom: -120%;
    left: 0;
    right: 0;
    z-index: 999;
    border-radius: 30px 30px 0 0;
    transition: .4s linear;
}

.modal-content.verification-aside.mobile-menu-open {
    bottom: 0;
}



.verification-aside {
    position: fixed;
    bottom: -120%;
    left: 0;
    right: 0;
    z-index: 999;
    border-radius: 30px 30px 0 0;
    transition: .4s linear;
}

.modal-content.verification-aside.mobile-menu-open {
    bottom: 0;
    z-index: 9999;
}

.otp-aside {
    position: fixed;
    bottom: -120%;
    left: 0;
    right: 0;
    z-index: 999;
    border-radius: 30px 30px 0 0;
    transition: .4s linear;
}

.back-blur-fx.add-second.mobile-menu-open {
    opacity: 1;
    visibility: visible;
}

.modal-content.otp-aside.mobile-menu-open {
    bottom: 0;
    z-index: 9999;
}

.modal-content.close-sidebar.mobile-menu-close {
    bottom: -120%;
    z-index: 1;
    display: none;
}

.modal-content.add-second.mobile-menu-close {
    bottom: -120%;
    z-index: 1;
    display: none;
}


.order-placed-popup {
    position: fixed;
    bottom: -120%;
    left: 0;
    right: 0;
    z-index: 999;
    border-radius: 30px 30px 0 0;
    transition: .4s linear;
}

.back-blur-fx.add-placed.mobile-menu-open {
    opacity: 1;
    visibility: visible;
}

.modal-content.order-placed-popup.mobile-menu-open {
    bottom: 0;
    z-index: 9999;
}

button.order-placed-open {
    padding: 10px 15px;
    background: var(--title);
    width: 150px;
    text-align: center;
    border-radius: 30px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 600;
    color: var(--white);
    box-shadow: "0 3px 6px rgb(17 33 52 / 16%)";
    border: none;
    display: table;
    width: 100%;
    display: none;
}


.pro-details-box.mobile-fix .cart-editbx {
    padding: 0 25px;
}


.product-details-bottom .open-product {
    position: absolute;
    height: 34px;
    width: 34px;
    background: var(--title);
    border-radius: 100%;
    top: -10px;
    left: 50%;
    transform: translate(-50%, 0);
    display: none;
}

.product-details-bottom .open-product:before {
    position: absolute;
    font-family: "Font Awesome 5 Pro";
    content: "\f106";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    text-align: center;
    color: var(--white);
    font-weight: 400;
}

.mobile-menu-open.product-details-bottom .open-product:before {
    content: "\f107";
}

.mobile-menu-open.product-details-bottom {
    bottom: 0;
}



/* **** Lodder **** */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100000000;
    overflow: hidden;
}

.overlay .overlayDoor:before,
.overlay .overlayDoor:after {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    background: #0191d026;
    transition: 0.5s cubic-bezier(0.77, 0, 0.18, 1);
    transition-delay: 0.8s;
}

.overlay .overlayDoor:before {
    left: 0;
}

.overlay .overlayDoor:after {
    right: 0;
}

.overlay.loaded .overlayDoor:before {
    left: -50%;
}

.overlay.loaded .overlayDoor:after {
    right: -50%;
}

.overlay.loaded .overlayContent {
    opacity: 0;
    margin-top: -15px;
}

.loaded.overlay {
    display: none;
}

.overlay .overlayContent {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.5s cubic-bezier(0.77, 0, 0.18, 1);
}

.loader {
    width: 128px;
    height: 128px;
    border: 3px solid var(--blue);
    border-bottom: 3px solid transparent;
    border-radius: 50%;
    position: relative;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader .inner {
    width: 64px;
    height: 64px;
    border: 3px solid transparent;
    border-top: 3px solid var(--blue);
    border-radius: 50%;
    -webkit-animation: spinInner 1s linear infinite;
    animation: spinInner 1s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spinInner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-720deg);
    }
}

@keyframes spinInner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-720deg);
    }
}

/* **** End Lodder **** */

.productInfo-notes {
    max-height: 250px;
    overflow-y: scroll;
}

/* Scrollbar styling */

/* Works on Firefox */
.productInfo-notes * {
    scrollbar-width: thin;
    scrollbar-color: rgb(70, 70, 70) auto;
}

/* Works on Chrome, Edge, and Safari */
.productInfo-notes *::-webkit-scrollbar {
    width: 7px;
}

.productInfo-notes *::-webkit-scrollbar-track {
    background: transparent;
}

.productInfo-notes *::-webkit-scrollbar-thumb {
    background-color: rgb(70, 70, 70);
}

/* End scrollbar styling*/

.cart-colors {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.cart-color-item {
    background-color: #ddd;
    border: none;
    color: black;
    padding: 6px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 10px 8px 10px 0px;
    cursor: pointer;
    border-radius: 30px;
    font-weight: 600;
    text-wrap: nowrap;
}

.add-cart-sizes-container {

}

.add-cart-sizes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding: 20px 0 10px 0;
}

.add-cart-sizes-item {
    width: 70px !important;
    box-sizing: border-box;
    margin: 5px 5px 5px 5px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.add-cart-sizes-item-size {
    margin-bottom: 7px;
}

.add-cart-sizes-item-pricbx {
    display: flex;
    align-items: center;
    align-content: center;
    font-size: 12px;
    color: #009cea;
    font-weight: 600;
    justify-content: center;
}

.add-cart-sizes-item-inbx-in {
    height: 37px;
    /* width: 34px !important; */
    width: fit-content !important;
    border: 1px solid #d1d1d1;
    border-radius: 3px;
    display: inline-block;
    padding-top: 6px;
}

.add-cart-sizes-item-inbx {
    /* width: 45px !important; */
    text-align: center;
    cursor: pointer;
}

.add-cart-sizes-item-check {
    position: relative;
}
.round-check label {
    background-color: #c6c6c6;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    width: 16px;
  }
  
  .round-check label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 5px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 3px;
    transform: rotate(-45deg);
    width: 8px;
  }
  
  .round-check input[type="checkbox"] {
    visibility: hidden;
  }
  
  .round-check input[type="checkbox"]:checked + label {
    background-color: var(--blue);
    border-color: var(--blue);
  }
  
  .round-check input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
  
  .cart-save-size-btn {
    border-radius: 30px;
    border: none;
    padding:7px 15px;
    background: #0191D0;
    font-weight: bold;
    color: var(--white);
    font-size: 14px;
    min-width: 50px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    cursor: pointer;
    background: black;
    margin-left: 8px;
    margin-top: 10px;
}

.cart-save-size-btn-cancel {
    border-radius: 30px;
    border: none;
    padding: 9px 15px;
    font-weight: bold;
    font-size: 14px;
    min-width: 50px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    background-color: #ddd;
    color: black;
    cursor: pointer;
}


.card-qty-selected-color{
    background-color: var(--blue);
    color: white;
}

.copied-container {
    background: #efefef;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.copy-qty-btn {
    cursor: pointer;
    background: #0191d033;
    padding: 7px 15px;
    font-size: 15px;
    font-weight: 600;
    color: #0191D0;
}

.qty-change-btn {
    background: var(--blue);
    height: 45px;
    width: 45px;
    border-radius: 8px !important;
    font-weight: 800 !important;
    border: 0!important;
    color: white !important;
}
.PY_2{
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
}
.heartpop{
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    right: 0;
    position: absolute;
    border-radius: 30px;
    border: 1px solid #d8d8d8;
    margin: -27px 15px 0 0px;

}
.popupheart{
    padding: 15px 0 0 14px;
    font-size: 22px;
    color: #cacaca;
}

@media only screen and (max-width: 600px) {
    .amounttotal {
      display: flex;
    }
  }

  @media only screen and (max-width: 600px) {
    .copybtn {
        bottom: 15px;
        right: 15px;
        position: absolute;
    }
  }
  ::-webkit-scrollbar{
    height: 6px;
    width: 10px;
  }

  /* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(255, 255, 255); 
    border-radius: 50px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(221, 221, 221); 
    border-radius: 50px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #0191D0; 
  }