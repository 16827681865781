// @import "./custom.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.product-fil-modal-scroll {
  // max-height: 320px;
  max-height: 362px;
  overflow-y:auto;
  margin-top: 15px;
  border-top: 1px solid #dddddd;
  accent-color: #1e9acb;
}

.product-fil-modal-scrolltags {
  max-height: 280px;
  overflow-y:auto;
  border-top: 1px solid #dddddd;
  accent-color: #1e9acb;
}

.product-filter-modal-btn {
  background: var(--blue)!important;
  border: none !important;
}

.product-filter-modal-btn-clear {
  background: rgb(215, 215, 215)!important;
  color: #222222 !important;
  border: none !important;
}

.product-filter-modal-btn-close {
  background: rgb(232, 61, 61)!important;
  border: none !important;
}

.filter-checked-item-count{
  border: 0.1px solid #ef0000;
  border-radius: 50%;
  font-size: 13px;
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.multi-range-slider .thumb {
  background-color: #42b5cc !important;
}

.modal-filter-indicator{
  color: #2d2d2d;
  border-radius: 50%;
  padding-bottom: 0px;
  width: 10px;
  height: 11px;
  position: absolute;
  background: red;
  top: 7px;
  left: 20px;
  border: 2px solid white;
}