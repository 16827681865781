// @import './variables'; 
:root {
    --lightblue: #e4e7e9;
    --blue: #0191D0;
    --white: #ffffff;
    --darkblue:#112B46;
    --title: #131939;
    --orange: #F28500;
    --green:#0EB66B;
    --purple: #6A5ACD;
    --pink: #FF64B2;
    --black: #000000;
    --bor-color:rgba(19,25,57,0.10);
}
@import "../node_modules/bootstrap/scss/bootstrap";
@import "./css/style.css";
@import "./css/responsive.css";

