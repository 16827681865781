@media(min-width:1200px) and (max-width:1550px){
	.pro-dt-img img{
		height: 650px;
	}
	figure.categoryCard img {
		height: 300px;
	}
	.container{width: 100%; max-width: 100%;}
	/* **** Home Page **** */
	header{padding: 10px 0;}
	.nav-new{padding: 10px 0;}
	.head-left{width: 60%;}
	.head-right{width:40%;}
	.head-left h1{font-size:28px;font-weight:700;}


	.banner-sliders .slick-slide div{height: auto;}
	.atc-box{display: none;}
	.titlebx h2{font-size: 24px;margin: 0 0 10px;}
	figcaption h2{font-size: 18px;}
	figcaption p{font-size: 14px;}
	figcaption .log-btn{padding: 8px 10px;font-size: 12px;}
	figcaption .heart{width: 34px;height: 34px;font-size: 14px}
	.view-allpro{text-align: center;}
	.men-wrp .col-md-2,
	.women-wrp .col-md-2{max-width: 50%;}
	.men-wrp figcaption .log-btn,
	.women-wrp figcaption .log-btn{padding: 5px 8px;font-size: 10px;font-weight: 500;}
	.men-wrp figcaption .heart,
	.women-wrp figcaption .heart{width: 30px;height: 30px;font-size: 10px}
	.view-btn{margin-left: auto;margin-right: 0;margin-top: 15px;display: table;border-radius: 30px;}
	.women-wrp .view-btn{border-radius: 30px;}
	.men-wrp .view-btn{border-radius: 30px;}
	.category-wrp{padding: 15px 0 15px 10px;}
	.first-prd-slider{padding: 15px 0 15px 10px;}

	footer .footer-block h3{width: 100%;text-align: center;}

	.bottom-nav{width: 320px;padding: 10px 20px 10px 10px;}
	.bottom-nav ul li a img{width: 22px;}
	.bottom-nav ul li{margin-right:20px;}
	.bottom-nav ul li a{font-size: 14px;padding: 0px;}
	.bottom-nav ul li a .badge{font-size: 10px;right: 0;}
	.bottom-nav ul li.active a{width: 45px;height: 45px;}
	.bottom-nav ul li.active a img{width: auto;height: 22px;}
	.bottom-nav ul li:last-child{margin: 0;}
	/* **** End Home Page **** */

	/* **** product **** */
	.search-wrp{padding: 100px 0 0;}
	.products-head .col-md-7.col-sm-7{width: 70%;}
	.products-head .col-md-5.col-sm-5{width: 30%;}
	.product-listing-wrp.category-wrp{padding: 0;}
	.product-listing-wrp.category-wrp figcaption h2{font-size: 17px;}
	.product-listing-wrp.category-wrp figcaption .heart{height: 35px;width: 35px;font-size: 16px;}
	.product-listing-wrp.category-wrp figcaption h2{min-height: auto;}
	.product-listing-wrp.category-wrp figcaption p{font-size: 16px;}
	.product-listing-wrp.category-wrp .pro-pribxs h5{font-size: 24px;}
	.product-listing-wrp.category-wrp figcaption .log-btn{padding: 12px 12px;font-size: 15px;}
	/* **** End product **** */

	/* **** search **** */
	section.category-wrp.product-listing-wrp.search-wrapper figcaption h2{padding: 10px 0;font-size: 16px;min-height: auto;}
	/* **** End search **** */

	/* **** chooce-category **** */
	.category-choice-wrp{padding: 0;}
	.choice-bx h4{font-size: 14px;}
	.choice-bx h3{font-size: 14px;padding: 0;}
	.choice-lastbix .chbx-bx{min-height: 190px;}
	/* **** End chooce-category **** */

	.order-product-wrp .log-btn{font-size: 13px;padding: 10px 12px;}

	/* **** order **** */
	.order-product-wrp .order-block{min-height: auto;margin: 0 0 15px;}
	.place-fix a{padding: 12px 20px;font-size: 15px;font-weight: 600;}
	/* **** ENd order **** */
}

@media(min-width:992px) and (max-width:1199px){
	.pro-dt-img img{
		height: 650px;
	}
	figure.categoryCard img {
		height: 300px;
	}
	.container{width: 100%; max-width: 100%;}
	/* **** Home Page **** */
	header{padding: 10px 0;}
	.nav-new{padding: 10px 0;}
	.head-left{width: 60%;}
	.head-right{width:40%;}
	.head-left h1{font-size:28px;font-weight:700;}

	.banner-sliders .slick-slide div{height: auto;}
	.atc-box{display: none;}
	.titlebx h2{font-size: 24px;margin: 0 0 10px;}
	figcaption h2{font-size: 18px;}
	figcaption p{font-size: 14px;}
	figcaption .log-btn{padding: 8px 10px;font-size: 12px;}
	figcaption .heart{width: 34px;height: 34px;font-size: 14px}
	.view-allpro{text-align: center;}
	.men-wrp .col-md-2,
	.women-wrp .col-md-2{max-width: 50%;}
	.men-wrp figcaption .log-btn,
	.women-wrp figcaption .log-btn{padding: 5px 8px;font-size: 10px;font-weight: 500;}
	.men-wrp figcaption .heart,
	.women-wrp figcaption .heart{width: 30px;height: 30px;font-size: 10px}
	.view-btn{margin-left: auto;margin-right: 0;margin-top: 15px;display: table;border-radius: 30px;}
	.women-wrp .view-btn{border-radius: 30px;}
	.men-wrp .view-btn{border-radius: 30px;}
	.category-wrp{padding: 15px 0 15px 10px;}
	.first-prd-slider{padding: 15px 0 15px 10px;}

	.slider-arrivalbx{padding: 10px 15px 10px 0;}
	
	
	footer .footer-block h3{width: 100%;text-align: center;}

	.bottom-nav{width: 320px;padding: 10px 20px 10px 10px;}
	.bottom-nav ul li a img{width: 22px;}
	.bottom-nav ul li{margin-right:20px;}
	.bottom-nav ul li a{font-size: 14px;padding: 0px;}
	.bottom-nav ul li a .badge{font-size: 10px;right: 0;}
	.bottom-nav ul li.active a{width: 45px;height: 45px;}
	.bottom-nav ul li.active a img{width: auto;height: 22px;}
	.bottom-nav ul li:last-child{margin: 0;}
	/* **** End Home Page **** */

	/* **** product **** */
	.search-wrp{padding: 100px 0 0;}
	.products-head .col-md-7.col-sm-7{width: 70%;}
	.products-head .col-md-5.col-sm-5{width: 30%;}
	.product-listing-wrp.category-wrp{padding: 0;}
	.product-listing-wrp.category-wrp figcaption h2{font-size: 17px;}
	.product-listing-wrp.category-wrp figcaption .heart{height: 35px;width: 35px;font-size: 16px;}
	.product-listing-wrp.category-wrp figcaption h2{min-height: auto;}
	.product-listing-wrp.category-wrp figcaption p{font-size: 16px;}
	.product-listing-wrp.category-wrp .pro-pribxs h5{font-size: 24px;}
	.product-listing-wrp.category-wrp figcaption .log-btn{padding: 12px 12px;font-size: 15px;}
	/* **** End product **** */

	/* **** search **** */
	section.category-wrp.product-listing-wrp.search-wrapper figcaption h2{padding: 10px 0;font-size: 20px;min-height: auto;}
	/* **** End search **** */

	/* **** chooce-category **** */
	.category-choice-wrp{padding: 0;}
	.choice-bx h4{font-size: 14px;}
	.choice-bx{min-height: 190px;}
	.choice-bx h3{font-size: 14px;padding: 0;}
	.choice-lastbix .chbx-bx{min-height: 180px;}
	/* **** End chooce-category **** */

	/* **** order **** */
	.order-product-wrp .order-block{min-height: auto;margin: 0 0 15px;}
	.place-fix a{padding: 12px 20px;font-size: 15px;font-weight: 600;}
	.buket-wrp .buket-gallery{display: block;flex-flow:unset;}
	.buket-wrp .buket-grid-block{column-count:1;}
	.order-product-wrp .log-btn{margin-left: auto;margin-right: 15px;}
	/* **** ENd order **** */


	/* **** product-details **** */
	.pro-dt-hed h3{font-size: 26px;}
	.pro-dt-hed h4{font-size: 30px;}
	.pro-dt-hed .log-btn{font-size: 14px;font-weight: 500;padding: 8px 20px;}
	/* **** End product-details **** */
}

@media(min-width:768px) and (max-width:991px){
	.pro-dt-img img{
		height: 650px;
	}
	figure.categoryCard img {
		height: 250px;
	}
	.container{width: 100%; max-width: 100%;}
	/* **** Home Page **** */
	header{padding: 10px 0;}
	.nav-new{padding: 10px 0;}
	.head-left{width: 60%;}
	.head-right{width:40%;}
	.head-left h1{font-size:28px;font-weight:700;}

	.banner-sliders .slick-slide div{height: auto;}
	.atc-box{display: none;}
	.titlebx h2{font-size: 24px;margin: 0 0 10px;}
	figcaption h2{font-size: 18px;}
	figcaption p{font-size: 15px;}
	figcaption .log-btn{padding: 8px 10px;font-size: 12px;}
	figcaption .heart{width: 34px;height: 34px;font-size: 14px}
	.view-allpro{text-align: center;}
	.men-wrp .col-md-2,
	.women-wrp .col-md-2{max-width: 50%;}
	.men-wrp figcaption .log-btn,
	.women-wrp figcaption .log-btn{padding: 5px 8px;font-size: 10px;font-weight: 500;}
	.men-wrp figcaption .heart,
	.women-wrp figcaption .heart{width: 30px;height: 30px;font-size: 10px}
	.view-btn{margin-left: auto;margin-right: 0;margin-top: 15px;display: table;border-radius: 30px;}
	.women-wrp .view-btn{border-radius: 30px;}
	.men-wrp .view-btn{border-radius: 30px;}
	.category-wrp{padding: 15px 0 15px 10px;}
	.first-prd-slider{padding: 15px 0 15px 10px;}

	.slider-arrivalbx{padding: 10px 15px 10px 0;}
	
	
	footer .footer-block h3{width: 100%;text-align: center;}

	.bottom-nav{width: 250px;padding: 10px 20px 10px 10px;bottom: 30px;}
	.bottom-nav ul li a img{width: 22px;}
	.bottom-nav ul li{margin-right:20px;}
	.bottom-nav ul li a{font-size: 14px;padding: 0px;}
	.bottom-nav ul li a .badge{font-size: 10px;right: 0;}
	.bottom-nav ul li.active a{width: 45px;height: 45px;}
	.bottom-nav ul li.active a img{width: auto;height: 22px;}
	.bottom-nav ul li:last-child{margin: 0;}
	/* **** End Home Page **** */


	/* **** product **** */
	.search-wrp{padding: 100px 0 0;}
	.search-bx{width: 100%;margin: 0 auto 20px;}
	/* **** End product **** */

	/* **** search **** */
	section.category-wrp.product-listing-wrp.search-wrapper figcaption h2{padding: 10px 0;font-size: 20px;min-height: auto;}
	/* **** End search **** */

	/* **** chooce-category **** */
	.category-choice-wrp{padding: 0;}
	.choice-bx h4{font-size: 14px;}
	.choice-bx{min-height: 190px;}
	.choice-bx h3{font-size: 14px;padding: 0;}
	.choice-lastbix .chbx-bx{min-height: 190px;}
	/* **** End chooce-category **** */

	/* **** product-cart **** */
	.inner-header{background: rgba(255,255,255,0.6);padding: 15px 0;border-radius: 0 0 20px 20px;position: absolute;z-index: 9;}
	.inner-header .btn_back{font-size: 14px;height: 40px;width: 40px;min-width: 40px;margin-right: 10px;}
	.products-details-wrp .col-md-12.col-sm-12.col-lg-5{padding: 0;}
	.inner-footer{display: none;}
	.inner-bottom-nav{display: none;}
	.mobile-fix{position: fixed;bottom: 0;left: 5%;right:5%;width: 90%;z-index: 9;border-radius:30px 30px 0 0;}
	.products-details-wrp{padding: 15px 0;}
	/* **** End product-cart **** */

	/* **** order **** */
	.btn_back{font-size: 14px;height: 40px;width: 40px;min-width: 40px;margin-right: 10px;}
	.order-product-wrp .order-block{min-height: auto;margin: 0 0 15px;}
	.order-product-wrp .order-box img{height: 50px;width: 50px;}
	.order-product-wrp .order-box h2{font-size: 16px;}
	.order-product-wrp .order-box p{font-size: 13px;}
	.order-block .col-md-8.col-sm-8{width: 60%;flex: 60%;max-width:60%;}
	.order-block .col-md-4.col-sm-4{width: 40%;flex:40%;max-width: 40%;}
	.order-product-wrp .log-btn{font-size: 12px;padding: 6px 12px; }
	.accordion .card .card-head h3{font-size: 24px;}
	.accordion .card .card-head h3:before{width: 54px;height: 33px;font-size: 16px;}

	.buket-wrp .buket-gallery{display: block;flex-flow:unset;}
	.buket-wrp .buket-grid-block{column-count:1;}
	.order-product-wrp .log-btn{margin-left: auto;margin-right: 15px;}
	.place-fix ul{display: block;}
	.place-fix ul li{width: 48%;margin: 0;}
	.place-fix ul li:nth-child(2){width: 48%;}
	.place-fix ul li:last-child{width: 80%;margin: 15px 0 0;}
	.place-fix ul li a{font-size: 16px;font-weight: 500;width: auto;display: block;text-align: center;}

	.modal-dialog{max-width: 608px;}
	#otp-popup .modal-dialog{width: 608px;}
	.address-popup .modal-dialog {max-width: 625px;}
	/* **** ENd order **** */

	/* **** 500 **** */
	.error-txt p br{display: none;}
	/* **** End 500 **** */

	.product-details-bottom .open-product{display: block;}
	.main-product-wrp .pro-dt-img a i{bottom: auto;left: 20px;top: 30px;}
	.product-details-bottom{
		float: none;
		position: fixed;
		left: 50%;
		bottom: 0;
		transform: translate(-50%, 0);
		z-index: 99;
		width: 90%;
		border-radius: 40px 40px 0 0;
		-webkit-transition: all 0.3s linear;
		transition: all 0.3s linear;}
		.default-product-info-snackbar{
			height: 0;
			overflow: hidden;
			opacity: 0;
		}
	.quick-act-pro ul li{display: block;margin: 0 0 15px;}
	.pro-dt-hed h3{font-size: 26px;}
	.pro-dt-hed h4{font-size: 35px;}
	.main-product-wrp .pro-details-box{padding:40px 25px 20px;}
	.main-product-wrp .quick-act-pro{top: -70px;}
	.main-product-wrp .pro-dt-hed .log-btn{padding: 10px 25px;font-size: 14px;line-height: 18px;margin: 10px 0 0;}
	.main-product-wrp .pro-lst-bx li{font-size: 15px;}

	.main-product-wrp .pro-details-box{padding:40px 25px 20px;width: 100%;border-radius: 40px 40px 0 0;}
}

@media(min-width:576px) and (max-width:767px){
	.pro-dt-img img{
		height: auto;
	}
	figure.categoryCard img {
		height: 200px;
	}
	.container{width: 100%; max-width: 100%;}
	/* **** Home Page **** */
	header{padding: 10px 0;}
	.nav-new{padding: 10px 0;}
	.head-left{width: 60%;}
	.head-right{width:40%;}
	.head-left h1{font-size:18px;font-weight:700;}
	.head-right li{margin-right: 10px;}
	.head-right li a.log-btn img{margin: 0px auto 0;display: table;position: absolute;top: 50%;transform: translate(0,-50%);left: 0;right: 0;}
	.head-right li a.log-btn span{display: none;}
	.head-right li a.log-btn{width: 35px;height: 35px;padding: 0;display: block;position: relative;}
	.head-right li a.user-img img{width: 50px;height: 50px;}

	.banner-sliders .slick-slide div{height: auto;}
	.products-wrp{padding: 15px 0 15px 15px;}
	.atc-box{display: none;}
	.titlebx h2{font-size: 24px;margin: 0 0 10px;}
	figcaption h2{font-size: 18px;}
	figcaption p{font-size: 15px;}
	figcaption .log-btn{padding: 8px 10px;font-size: 12px;}
	figcaption .heart{width: 34px;height: 34px;font-size: 14px}
	.men-wrp,.women-wrp{padding: 15px 0;}
	.view-allpro{text-align: center;}
	.men-wrp .col-md-2,
	.women-wrp .col-md-2{max-width: 50%;}
	.men-wrp figcaption .log-btn,
	.women-wrp figcaption .log-btn{padding: 5px 8px;font-size: 10px;font-weight: 500;}
	.men-wrp figcaption .heart,
	.women-wrp figcaption .heart{width: 30px;height: 30px;font-size: 10px}
	.view-btn{margin-left: auto;margin-right: 0;margin-top: 15px;display: table;border-radius: 30px;}
	.women-wrp .view-btn{border-radius: 30px;}
	.men-wrp .view-btn{border-radius: 30px;}

	.banner-sliders .slick-slide div{height: auto;}
	.products-wrp{padding: 15px 0 15px 15px;}
	.atc-box{display: none;}
	.titlebx h2{font-size: 22px;margin: 0 0 10px;padding: 0;}
	.pro-lst-blks .owl-prev:before, .pro-lst-blks .owl-next:before{height: 30px;width: 30px;line-height: 30px;font-size: 13px;}
	.pro-lst-blks .owl-next{right: 40px;}
	figcaption h2{font-size: 18px;}
	figcaption p{font-size: 15px;}
	figcaption .log-btn{padding: 10px 15px;font-size: 14px;}

	.arrival-slider .owl-carousel{right: -12px;}
	.slider-arrivalbx{padding: 10px 25px 10px 0;}
	.pro-lst-blks .owl-nav{right: 20px;}
	figure:after{right: -15px;}
	figcaption .mobolbtn{padding: 5px 8px;font-size: 10px;font-weight: 500;}
	figcaption .heart{width: 30px;height: 30px;font-size: 10px;}


	
	footer .footer-block h3{font-size: 16px;}
	footer .footer-block h2{font-size: 28px;}
	footer .footer-block p{font-size: 16px;line-height: 24px;text-align: center;}
	footer .footer-block .footer-ic ul li{margin-right: 10px;}
	footer .footer-block .footer-ic ul li a img{max-width: 40px;}

	.bottom-nav{width: 310px;padding: 10px 20px 10px 10px;bottom: 30px;}
	.bottom-nav ul li a img{width: 22px;}
	.bottom-nav ul li{margin-right:20px;}
	.bottom-nav ul li a{font-size: 14px;padding: 0px;}
	.bottom-nav ul li a .badge{font-size: 10px;right: 0;}
	.bottom-nav ul li.active a{width: 45px;height: 45px;}
	.bottom-nav ul li.active a img{width: auto;height: 22px;}
	.bottom-nav ul li:last-child{margin: 0;}

	figcaption .log-btn{display: none;}
	figcaption .mobolbtn{display: inline-block;}
	
	.pro-dt-hed .log-btn{display: none !important;}
	.pro-dt-hed .mobolbtn{display: inline-block !important;}
	.addQty.log-btn{display: none !important;}
	.addQty.mobolbtn{display: inline-block !important;}

	.bucketEditIcon{display: none !important;}
	.bucketEditIcon.mobolbtn{display: flex !important;}

	.modal-dialog{width:70%;margin: 0 auto;}
	.copy-popup{padding: 0 !important;}
	.copy-popup-block h2{font-size: 24px;margin: 0 0 15px;}
	.copy-popup-block p{width: 100%;font-size: 16px;line-height: 24px;margin: 0 0 15px;}
	.copy-popup-block{padding: 25px;}
	.copy-popup-block .cart-actionbar .btn-main{min-width: 100px;}
	/* **** End Home Page **** */

	/* **** product **** */
	.search-wrp{padding: 100px 0 0;}
	.search-bx{width: 100%;margin: 0 auto 20px;}
	.products-head .col-md-7.col-sm-7{width: 70%;}
	.products-head .col-md-5.col-sm-5{width: 30%;}
	.product-listing-wrp.category-wrp{padding: 0;}
	.product-listing-wrp.category-wrp figcaption h2{font-size: 20px;}
	.product-listing-wrp.category-wrp figcaption .heart{height: 35px;width: 35px;font-size: 16px;}
	.product-listing-wrp.category-wrp figcaption h2{min-height: auto;}
	.product-listing-wrp.category-wrp figcaption p{font-size: 16px;}
	.product-listing-wrp.category-wrp .pro-pribxs h5{font-size: 24px;}
	.product-listing-wrp.category-wrp figcaption .log-btn{padding: 10px 12px;font-size: 13px;display: none;}
	.product-listing-wrp.category-wrp figcaption .pro-pribxs .mobolbtn{display: inline-block;}
	/* **** End product **** */

	/* **** search **** */
	.search-wrapper .women-box{width: 95%;}
	section.category-wrp.product-listing-wrp.search-wrapper figcaption h2{padding:5px 0;font-size: 18px;min-height: auto;}
	/* **** End search **** */

	/* **** chooce-category **** */
	#mobile-search{display: block;}
	#mobile-search-dnone{display:none;}
	.category-choice-wrp{padding: 0;}
	.choice-bx h4{font-size: 14px;}
	.choice-bx.on-page .chbx-bx, .choice-bx:hover .chbx-bx{margin: 0;}
	.choice-bx h3{font-size: 14px;padding: 0;}
	.choice-lastbix .chbx-bx{min-height: 190px;}
	.category-wrp.product-listing-wrp.choice-wrp-cat figcaption h2{font-size: 16px;padding: 10px 35px 10px 0;}
	.category-wrp.product-listing-wrp.choice-wrp-cat figcaption .heart{height: 28px;width: 28px;font-size: 12px;}
	.category-wrp.product-listing-wrp.choice-wrp-cat figcaption h2{min-height: auto;}
	.category-wrp.product-listing-wrp.choice-wrp-cat figcaption p{font-size: 14px;}
	.category-wrp.product-listing-wrp.choice-wrp-cat .pro-pribxs h5{font-size: 24px;}
	.category-wrp.product-listing-wrp.choice-wrp-cat figcaption .log-btn{padding: 10px 15px;font-size: 14px;}
	/* **** End chooce-category **** */

	/* **** product-cart **** */
	.inner-header{background: rgba(255,255,255,0.6);padding: 15px 0;border-radius: 0 0 20px 20px;position: absolute;z-index: 9;}
	.inner-header .btn_back{font-size: 14px;height: 40px;width: 40px;min-width: 40px;margin-right: 10px;}
	.products-details-wrp .col-md-12.col-sm-12.col-lg-5{padding: 0;}
	.inner-footer{display: none;}
	.inner-bottom-nav{display: none;}
	.mobile-fix{position: fixed;bottom: 0;left: 0;right: 0;width: 100%;z-index: 9;border-radius:30px 30px 0 0;}
	.products-details-wrp{padding: 15px 0;}
	/* **** End product-cart **** */

	/* **** order **** */
	.order-product-wrp .order-block{min-height: auto;margin: 0 0 15px;}
	.buket-wrp .buket-gallery{display: block;flex-flow:unset;}
	.buket-wrp .buket-grid-block{column-count:1;}
	.place-fix{border-radius: 20px 20px 0 0;box-shadow: "0 -7px 6px rgb(0 0 0 / 7%)";}
	.place-fix .left-brd{display: none;}
	.place-fix ul{display: block;}
	.total-bx{display: flex;align-items: center;margin: 0;}
	.place-fix ul li:last-child{display: none;}
	.place-fix ul li{display: block;margin: 0;font-size: 16px;width:150px;}
	.place-fix ul li:nth-child(2){width: 100%;}
	.place-fix a{width: 120px;font-size: 13px;margin-left: auto;padding: 10px 14px;text-align: center;font-weight: 600;}
	.place-fix ul li span{display: inline-block;}
	.order-product-wrp .buket-wrp{padding: 30px 0 70px;}
	.order-product-wrp .plase-mobile-box{display: block;}
	/* **** ENd order **** */

	/* **** modal **** */
	.add-text-block h3{font-size: 26px;}
	.add-text-block h4{font-size: 22px;}
	.add-text-block{padding: 25px;}
	#add-text-popup .cart-actionbar .btn-main.calbtn{min-width:100px; padding: 8px 10px;}
	#add-text-popup .cart-actionbar .btn-main{min-width: 100px;padding: 8px 10px;}
	#delete-popup .cart-actionbar .btn-main.calbtn{min-width:100px; padding: 8px 10px;}
	#delete-popup .cart-actionbar .btn-main{min-width: 100px;padding: 8px 10px;}
	a.modal-mobile{display:block;}
	
	.mobile-num-bx{width: 100%;}
	.otp-wrp .mobile-num-bx{width: 100%;}
	.add-text-block p{font-size: 14px;}
	.otp-wrp .trash-btns .btn-main{padding: 10px 15px;}
	.back-step i{height: 40px;width: 40px;font-size: 14px;line-height: 40px;}

	.address-popup .modal-dialog{top: auto;transform: unset !important;margin: 30px auto;}
	.address-popup a.btn-main.canbtn{display: none;}
	.address-popup .alter-msg-bx h6.error-msg{margin: 0 0 15px;}
	.address-popup .add-row .row .btn-group{display: none;}
	.address-popup .add-row .row .btn-group .btn-main{display: block;width: 100%;font-size: 15px;font-weight: 500;text-align: center;}
	.address-popup button.order-placed-open{display: block;}
	.order-placed-wrp{border-radius: 25px 25px 0 0;}

	.verification-aside .modal-content{border-radius: 25px 25px 0 0;}
	.otp-aside .modal-content{border-radius: 25px 25px 0 0;}
	.verification-aside .modal-content{border-radius: 25px 25px 0 0;}
	/* **** End Modal **** */
	/* **** ENd order **** */

	/* **** 404 **** */
	.center-pager img{width: 400px;}
	.error-txt h3{font-size: 26px;}
	.error-txt p{font-size: 16px;margin: 0 0 20px;}
	.error-txt{padding: 0 0 0 25px;width: 80%;margin: -100px auto 0;}
	.error-txt .btn-main{font-size: 15px;padding: 12px 40px;}
	/* **** End 404 **** */

	/* **** 500 **** */
	.error-txt p br{display: none;}
	/* **** End 500 **** */

	.product-details-bottom .open-product{display: block;}
	.main-product-wrp .pro-dt-img a i{bottom: auto;left: 20px;top: 30px;}
	.product-details-bottom{
		width: 100%;
		float: none;
		position: fixed;
		left: 50%;
		bottom: 0;
		transform: translate(-50%, 0);
		z-index: 99;
		border-radius: 40px 40px 0 0;
		-webkit-transition: all 0.3s linear;
		transition: all 0.3s linear;}
	.default-product-info-snackbar{
		height: 0;
		overflow: hidden;
		opacity: 0;
	}
	.quick-act-pro ul li{display: block;margin: 0 0 15px;}
	.pro-dt-hed h3{font-size: 26px;}
	.pro-dt-hed h4{font-size: 35px;}
	.main-product-wrp .pro-details-box{padding:40px 25px 20px;}
	.main-product-wrp .quick-act-pro{top: -70px;}
	.main-product-wrp .pro-dt-hed .log-btn{padding: 10px 25px;font-size: 14px;line-height: 18px;margin: 10px 0 0;}
	.main-product-wrp .pro-lst-bx li{font-size: 15px;}
	
	.mobilecart-aside .cart-hed ul li:first-child{padding: 10px 10px 10px 35px;}

	.choice-bx{min-height: 180px;}

	.main-product-wrp .pro-details-box{padding:40px 25px 20px;width: 100%;border-radius: 40px 40px 0 0;}
}

@media(min-width:360px) and (max-width:575px){
	/* .cp h1.mobileWidthChange {
		width: 170px !important;
	} */
	.pro-dt-img img{
		height: auto;
	}
	figure.categoryCard img {
		height: 200px;
	}
	.container{width: 100%; max-width: 100%;}

	/* **** Home Page **** */
	header{padding: 10px 0;}
	.nav-new{padding: 10px 0;}
	.head-left{width: 60%;}
	.head-right{width:40%;}
	.head-left h1{font-size:18px;font-weight:600;}
	.head-right li{margin-right: 10px;}
	.head-right li a.log-btn img{margin: 0px auto 0;display: table;position: absolute;top: 50%;transform: translate(0,-50%);left: 0;right: 0;}
	.head-right li a.log-btn span{display: none;}
	.head-right li a.log-btn{width: 35px;height: 35px;padding: 0;display: block;position: relative;}
	.head-right li a.user-img img{width: 50px;height: 50px;}

	.banner-sliders .slick-slide div{height: auto;}
	.products-wrp{padding: 15px 0 15px 5px;}
	.atc-box{display: none;}
	.titlebx h2{font-size: 22px;margin: 0 0 10px;padding: 0;}
	.pro-lst-blks .owl-prev:before, .pro-lst-blks .owl-next:before{height: 30px;width: 30px;line-height: 30px;font-size: 13px;}
	.pro-lst-blks .owl-next{right: 40px;}
	figcaption h2{font-size: 18px;}
	figcaption p{font-size: 15px;}
	figcaption .log-btn{padding: 10px 15px;font-size: 14px;}

	.arrival-slider .owl-carousel{right: -10px;}
	.pro-lst-blks .owl-nav{right: 20px;}
	.slider-arrivalbx{padding: 10px 25px 10px 0;}
	figure:after{right: -15px;}
	figcaption .mobolbtn{padding: 5px 8px;font-size: 10px;font-weight: 500;}
	figcaption .heart{width: 30px;height: 30px;font-size: 10px;}

	.men-wrp,.women-wrp{padding: 15px 0;}
	.men-wrp .col-xl-2,
	.women-wrp .col-xl-2{max-width: 50%;}
	.men-wrp figcaption .log-btn,
	.women-wrp figcaption .log-btn{padding: 5px 8px;font-size: 10px;font-weight: 500;}
	.men-wrp figcaption .heart,
	.women-wrp figcaption .heart{width: 30px;height: 30px;font-size: 10px}
	.view-btn{border-radius: 30px;}
	.women-wrp .view-btn{border-radius: 30px;}
	.men-wrp .view-btn{border-radius: 30px;}
	
	footer .footer-block h3{font-size: 16px;}
	footer .footer-block h2{font-size: 28px;}
	footer .footer-block p{font-size: 16px;line-height: 24px;text-align: center;}
	footer .footer-block .footer-ic ul li{margin-right: 10px;}
	footer .footer-block .footer-ic ul li a img{max-width: 40px;}

	.bottom-nav{width: 225px;bottom: 25px;}
	.bottom-nav ul li a img{width: 20px;}
	.bottom-nav ul li{margin-right:20px;}
	.bottom-nav ul li a{font-size: 14px;padding: 0;}
	.bottom-nav ul li a .badge{font-size: 10px;right: 0;}
	.bottom-nav ul li.active a{width: 45px;height: 45px;}
	.bottom-nav ul li.active a img{width: auto;height: 22px;}
	.bottom-nav ul li:last-child{margin: 0;}

	figcaption .log-btn{display: none;}
	figcaption .mobolbtn{display: inline-block;}

	.pro-dt-hed .log-btn{display: none !important;}
	.pro-dt-hed .mobolbtn{display: inline-block !important; }
	.addQty.log-btn{display: none !important;}
	.addQty{display: none !important;}
	.addQty.mobolbtn{display: inline-block !important; }
	
	.bucketEditIcon{display: none !important;}
	.bucketEditIcon.mobolbtn{display: flex !important;}


	figcaption h2{font-size: 16px;}
	figcaption p{font-size: 14px;}
	figure{padding: 10px;}

	.cart-hed ul li{width: 30% !important;}
	.mobilecart-aside .cart-editbx ul li{min-height: auto;width: 30% !important;}
	.mobilecart-aside .cart-dt h4{font-size: 18px;}
	.cart-editbx ul li{font-size: 14px;}
	.mobilecart-aside .cart-editbx ul li{padding: 0 10px;}
	.cart-editbx ul li h3{font-size: 18px;}
	.cart-editbx ul, .cart-hed ul{display: flex;align-items: center;}
	.cart-editbx{padding: 5px 15px;}
	.cart-actionbar .btn-main{padding: 8px 15px;font-size: 14px;}
	.boron{margin-top: 10px;padding-top: 10px;}
	.mobilecart-aside .cart-actionbar{text-align: center;}

	.modal-dialog{width: 90%;margin: 0 auto;}
	.copy-popup{padding: 0 !important;}
	.copy-popup-block h2{font-size: 24px;margin: 0 0 15px;}
	.copy-popup-block p{width: 100%;font-size: 16px;line-height: 24px;margin: 0 0 15px;}
	.copy-popup-block{padding: 25px;}
	.copy-popup-block .cart-actionbar .btn-main{min-width: 100px;}
	/* **** End Home Page **** */

	/* **** product **** */
	.search-wrp{padding: 100px 0 0;}
	.search-bx{width: 100%;margin: 0 auto 20px;}
	.products-head .col-md-7.col-sm-7{width: 70%;}
	.products-head .col-md-5.col-sm-5{width: 30%;}
	.product-listing-wrp.category-wrp{padding: 0;}
	.product-listing-wrp.category-wrp figcaption h2{font-size: 20px;}
	.product-listing-wrp.category-wrp figcaption .heart{height: 35px;width: 35px;font-size: 16px;}
	.product-listing-wrp.category-wrp figcaption h2{min-height: auto;}
	.product-listing-wrp.category-wrp figcaption p{font-size: 16px;}
	.product-listing-wrp.category-wrp .pro-pribxs h5{font-size: 24px;}
	.product-listing-wrp.category-wrp figcaption .log-btn{padding: 10px 12px;font-size: 13px;display: none;}
	.product-listing-wrp.category-wrp figcaption .pro-pribxs .mobolbtn{display: inline-block;}
	/* **** End product **** */

	/* **** search **** */
	.search-wrapper .col-xl-2.col-lg-3.col-md-4.col-sm-6.col-xs-6{width: 50%;padding: 0 10px;}
	.search-wrapper .women-box{width: 95%;}
	.search-wrapper .women-box figure:after{right: -15px;}
	section.category-wrp.product-listing-wrp.search-wrapper figcaption h2{padding:5px 0;font-size: 13px;min-height: auto;}
	/* **** End search **** */

	/* **** chooce-category **** */
	#mobile-search{display: block;}
	#mobile-search-dnone{display:none;}
	.category-choice-wrp{padding: 0;}
	.choice-bx h4{font-size: 14px;}
	.choice-bx.on-page .chbx-bx, .choice-bx:hover .chbx-bx{margin: 0;}
	.choice-bx h3{font-size: 14px;padding: 0;}
	.choice-lastbix .chbx-bx{min-height: 190px;}
	/* **** End chooce-category **** */

	/* **** product-cart **** */
	.inner-header{background: rgba(255,255,255,0.6);padding: 15px 0;border-radius: 0 0 20px 20px;position: absolute;z-index: 9;}
	.inner-header .btn_back{font-size: 14px;height: 40px;width: 40px;min-width: 40px;margin-right: 10px;}
	.products-details-wrp .col-md-12.col-sm-12.col-lg-5{padding: 0;}
	.inner-footer{display: none;}
	.inner-bottom-nav{display: none;}
	.mobile-fix{position: fixed;bottom: 0;left: 0;right: 0;width: 100%;z-index: 9;border-radius:30px 30px 0 0;}
	.products-details-wrp .owl-carousel .owl-item img{min-height: 500px;object-fit: cover;}
	.products-details-wrp .pro-dt-img a i{bottom: 160px;}
	.cart-actionbar{border: none;}
	.cart-actionbar .btn-main{min-width: 120px;padding: 8px 30px;}
	.cart-hed ul li{font-size: 14px;font-weight: 500;}
	.cart-editbx ul li{min-height: auto;padding: 0;}
	.cart-editbx ul li.epclone{padding: 0;}
	.inner-cart-lst{height: 270px;}
	.cart-editbx ul li .form-control{width: 70px;}
	/* **** End product-cart **** */

	/* **** order **** */
	.btn_back{font-size: 14px;height: 40px;width: 40px;min-width: 40px;margin-right: 10px;}
	.order-product-wrp .order-block{min-height: auto;margin: 0 0 15px;}
	.order-product-wrp .order-box img{height: 60px;width: 60px;}
	.order-product-wrp .order-box h2{font-size: 18px;}
	.order-product-wrp .order-box p{font-size: 12px;}
	.order-block .col-md-8.col-sm-8{width: 65%;}
	.order-block .col-md-4.col-sm-4{width: 35%;}
	.order-product-wrp .log-btn{font-size: 12px;padding: 6px 12px; }
	.accordion .card .card-head h3{font-size: 24px;}
	.accordion .card .card-head h3:before{width: 54px;height: 33px;font-size: 16px;}

	.buket-wrp .buket-gallery{display: block;flex-flow:unset;}
	.buket-wrp .buket-grid-block{column-count:1;}

	.buket-bix-header .col-md-8.col-sm-8{width: 60%;}
	.buket-bix-header .col-md-4.col-sm-4{width: 40%;}
	.order-product-wrp .buket-wrp .buket-box .buket-info img{height: 55px;width: 55px;}
	.order-product-wrp .buket-wrp .buket-box .buket-info h2{font-size: 15px;}
	.order-product-wrp .buket-wrp .buket-box .buket-ic ul li a{height: 35px;width: 35px;font-size: 14px;}
	.order-product-wrp .buket-wrp .buket-box .buket-ic ul li{margin-right: 10px;}

	.order-product-wrp .buket-wrp .tbl-header ul li{font-size: 15px;word-break: break-all;}
	.order-product-wrp .buket-wrp .tbl-body ul li{font-size: 16px;}
	.total-box h3{font-size: 16px;}
	/* **** ENd order **** */


	.place-fix{border-radius: 20px 20px 0 0;box-shadow: "0 -7px 6px rgb(0 0 0 / 7%)";}
	.place-fix .left-brd{display: none;}
	.place-fix ul{display: block;}
	.total-bx{display: flex;align-items: center;margin: 0;}
	.place-fix ul li:last-child{display: none;}
	.place-fix ul li{display: block;margin: 0;font-size: 16px;width:150px;}
	.place-fix ul li:nth-child(2){width: 100%;}
	.place-fix a{width: 120px;font-size: 13px;margin-left: auto;padding: 10px 14px;text-align: center;font-weight: 600;}
	.place-fix ul li span{display: inline-block;}
	.order-product-wrp .buket-wrp{padding: 30px 0 70px;}
	.order-product-wrp .plase-mobile-box{display: block;}
	/* **** ENd order **** */

	/* **** modal **** */
	.add-text-block h3{font-size: 26px;}
	.add-text-block h4{font-size: 22px;}
	.add-text-block{padding: 25px;}
	#add-text-popup .cart-actionbar .btn-main.calbtn{min-width:100px; padding: 8px 10px;}
	#add-text-popup .cart-actionbar .btn-main{min-width: 100px;padding: 8px 10px;}
	#delete-popup .cart-actionbar .btn-main.calbtn{min-width:100px; padding: 8px 10px;}
	#delete-popup .cart-actionbar .btn-main{min-width: 100px;padding: 8px 10px;}
	a.modal-mobile{display:block;}
	
	.mobile-num-bx{width: 100%;}
	.otp-wrp .mobile-num-bx{width: 100%;}
	.add-text-block p{font-size: 14px;}
	.otp-wrp .trash-btns .btn-main{padding: 10px 15px;}
	.back-step i{height: 40px;width: 40px;font-size: 14px;line-height: 40px;}

	.address-popup .modal-dialog{top: auto;transform: unset !important;margin: 30px auto;}
	.address-popup a.btn-main.canbtn{display: none;}
	.address-popup .alter-msg-bx h6.error-msg{margin: 0 0 15px;}
	.address-popup .add-row .row .btn-group{display: none;}
	.address-popup .add-row .row .btn-group .btn-main{display: block;width: 100%;font-size: 15px;font-weight: 500;text-align: center;}
	.address-popup button.order-placed-open{display: block;}
	.order-placed-wrp{border-radius: 25px 25px 0 0;}

	.verification-aside .modal-content{border-radius: 25px 25px 0 0;}
	.otp-aside .modal-content{border-radius: 25px 25px 0 0;}
	.verification-aside .modal-content{border-radius: 25px 25px 0 0;}
	/* **** End Modal **** */

	/* **** 404 **** */
	.center-pager img{width: 300px;}
	.error-txt h3{font-size: 26px;}
	.error-txt p{font-size: 16px;margin: 0 0 20px;}
	.error-txt{padding: 0 0 0 25px;width: 80%;margin: -100px auto 0;}
	.error-txt .btn-main{font-size: 15px;padding: 12px 40px;}
	/* **** End 404 **** */

	/* **** 500 **** */
	.refrewrp.error-txt{width: 100%;padding: 0 15px 0 20px;}
	.error-txt p br{display: none;}
	/* **** End 500 **** */

	.product-details-bottom .open-product{display: block;}
	.main-product-wrp .pro-dt-img a i{bottom: auto;left: 20px;top: 30px;}
	.product-details-bottom{width: 100%;float: none;position: fixed;left: 50%;transform: translate(-50%, 0);z-index: 99;bottom: 0;border-radius: 40px 40px 0 0;-webkit-transition: all 0.3s linear;transition: all 0.3s linear;}
	.pro-dt-hed h3{font-size: 20px;}
	.pro-dt-hed h4{font-size: 30px;}
	.quick-act-pro ul li{display: block;margin: 0 0 15px;}
	.main-product-wrp .pro-details-box{padding:40px 25px 20px;}
	.main-product-wrp .quick-act-pro{top: -50px;}
	.main-product-wrp .pro-dt-hed .log-btn{padding: 10px 25px;font-size: 14px;line-height: 18px;margin: 10px 0 0;}
	.main-product-wrp .pro-lst-bx li{font-size: 15px;}
	.main-product-wrp .note-bx h4{font-size: 18px;margin: 15px 0 0;}
	.main-product-wrp .note-bx p{font-size: 14px;margin: 15px 0 0;line-height: 20px;}
	.main-product-wrp .quick-act-pro ul li a i{height: 40px;width: 40px;font-size: 15px;line-height: 40px;}
	.default-product-info-snackbar{
		height: 0;
		overflow: hidden;
		opacity: 0;
	}

	.pro-details-box.mobile-fix .cart-editbx{padding: 0 0;}
	/*.cart-editbx ul{padding: 0 25px;}
	.cart-editbx ul li h3{margin: 10px 0 0;}*/
	.cart-editbx ul li h3{margin: 10px 0 0;}
	.cart-hed ul li{width: 35%;}
	.mobilecart-aside .cart-editbx ul li{width: 35%;}
	.mobilecart-aside .cart-hed ul li:first-child{padding: 10px 10px 10px 18px;}
	.choice-bx{min-height: 160px;}

	.products-details-wrp{padding: 15px 0;}

	.main-product-wrp .pro-details-box{padding:40px 25px 20px;width: 100%;border-radius: 40px 40px 0 0;}
	.figure img{height:215px;}
}

@media (max-width: 359px) {	
	/* .cp h1.mobileWidthChange {
		width: 130px !important;
	} */
	.pro-dt-img img{
		height: auto
	}
	figure.categoryCard img {
		height: 200px;
	}
	.container{width: 100%; max-width: 100%;}

	/* **** Home Page **** */
	header{padding: 10px 0;}
	.nav-new{padding: 10px 0;}
	.head-left{width: 60%;}
	.head-right{width:40%;}
	.head-left h1{font-size: 15px;font-weight:600;}
	.head-right li{margin-right: 10px;}
	.head-right li a.log-btn img{margin: 0px auto 0;display: table;position: absolute;top: 50%;transform: translate(0,-50%);left: 0;right: 0;}
	.head-right li a.log-btn span{display: none;}
	.head-right li a.log-btn{width: 35px;height: 35px;padding: 0;display: block;position: relative;}
	.head-right li a.user-img img{width: 50px;height: 50px;}

	.bottom-nav{width: 200px;bottom: 20px;}
	.bottom-nav ul li a img{height: 20px;}
	.bottom-nav ul li{margin-right:20px;}
	.bottom-nav ul li a{font-size: 12px;padding: 0;}
	.bottom-nav ul li a .badge{font-size: 10px;right: 0;}
	.bottom-nav ul li.active a{width: 45px;height: 45px;}
	.bottom-nav ul li.active a img{max-width: auto;width: auto;height: 25px;}
	.bottom-nav ul li:last-child{margin: 0;}

	footer .footer-block h3{font-size: 16px;}
	footer .footer-block h2{font-size: 28px;}
	footer .footer-block p{font-size: 15px;line-height: 24px;text-align: center;}
	footer .footer-block .footer-ic ul li{margin-right: 10px;}
	footer .footer-block .footer-ic ul li a img{max-width: 40px;}

	.titlebx h2{padding: 10px 0 0;font-size: 22px;}
	.arrival-slider .owl-carousel{right: -10px;}
	.pro-lst-blks .owl-nav{right: 20px;}

	.women-wrp{padding:0;}
	.men-wrp{padding: 0;}	

	figcaption .log-btn{display: none;}
	figcaption .mobolbtn{display: inline-block;}

	.pro-dt-hed .log-btn{display: none !important;}
	.pro-dt-hed .mobolbtn{display: inline-block !important; }
	.addQty.log-btn{display: none !important;}
	.addQty.mobolbtn{display: inline-block !important; }

	.bucketEditIcon{display: none !important;}
	.bucketEditIcon.mobolbtn{display: flex !important;}

	.mobilecart-aside .cart-editbx ul li{min-height: auto;}
	.mobilecart-aside .cart-dt h4{font-size: 18px;}
	.cart-editbx ul li{font-size: 14px;}
	.mobilecart-aside .cart-editbx ul li{padding: 0 5px;}
	.cart-editbx ul li h3{font-size: 18px;}
	.cart-editbx ul, .cart-hed ul{display: flex;align-items: center;}
	.cart-editbx{padding: 5px 15px;}
	.cart-actionbar .btn-main{padding: 8px 15px;font-size: 14px;}
	.boron{margin-top: 10px;padding-top: 10px;}
	.mobilecart-aside .cart-actionbar{text-align: center;}

	.modal-dialog{width: 90%;margin: 0 auto;}
	.copy-popup{padding: 0 !important;}
	.copy-popup-block h2{font-size: 24px;margin: 0 0 15px;}
	.copy-popup-block p{width: 100%;font-size: 16px;line-height: 24px;margin: 0 0 15px;}
	.copy-popup-block{padding: 25px;}
	.copy-popup-block .cart-actionbar .btn-main{min-width: 100px;}

	.pro-lst-blks .slick-prev, .pro-lst-blks .slick-next{top: -41px;}
	.pro-lst-blks .slick-prev:before, .pro-lst-blks .slick-next:before{width: 35px;height: 35px;line-height: 35px;font-size: 16px;}
	.pro-lst-blks .slick-prev, .pro-lst-blks .slick-next{right: 60px;}
	.pro-lst-blks .slick-next {left: auto; right: 15px;}
	/* **** End Home Page **** */


	/* **** product **** */
	.search-wrp{padding: 100px 0 0;}
	.search-bx{width: 100%;margin: 0 auto 20px;}
	.products-head .col-md-7.col-sm-7{width: 70%;}
	.products-head .col-md-5.col-sm-5{width: 30%;}
	.product-listing-wrp.category-wrp{padding: 0;}
	.product-listing-wrp.category-wrp figcaption h2{font-size: 16px;}
	.product-listing-wrp.category-wrp figcaption .heart{height: 26px;width: 26px;font-size: 10px;}
	.product-listing-wrp.category-wrp figcaption h2{min-height: auto;}
	.product-listing-wrp.category-wrp figcaption p{font-size: 12px;}
	.product-listing-wrp.category-wrp .pro-pribxs h5{font-size: 22px;}
	.product-listing-wrp.category-wrp figcaption .log-btn{padding: 10px 12px;font-size: 13px;display: none;}
	.product-listing-wrp.category-wrp figcaption .pro-pribxs .mobolbtn{display: inline-block;}
	/* **** End product **** */


	/* **** search **** */
	.search-wrapper .col-xl-2.col-lg-3.col-md-4.col-sm-6.col-xs-6{width: 50%;padding: 0 10px;}
	.search-wrapper .women-box{width: 95%;}
	.search-wrapper .women-box figure:after{right: -15px;}
	section.category-wrp.product-listing-wrp.search-wrapper figcaption h2{padding:5px 0;font-size: 13px;min-height: auto;}
	/* **** End search **** */

	/* **** chooce-category **** */
	#mobile-search{display: block;}
	#mobile-search-dnone{display:none;}
	.category-choice-wrp{padding: 0;}
	.choice-bx h4{font-size: 13px;}
	.choice-bx.on-page .chbx-bx, .choice-bx:hover .chbx-bx{margin: 0;}
	.choice-bx h3{font-size: 13px;padding: 0;}
	.choice-lastbix .chbx-bx{min-height: 190px;}
	.choice-bx{min-height: 160px;}
	/* **** End chooce-category **** */


	/* **** product-cart **** */
	.inner-header{background: rgba(255,255,255,0.6);padding: 15px 0;border-radius: 0 0 20px 20px;position: absolute;z-index: 9;}
	.inner-header .btn_back{font-size: 14px;height: 40px;width: 40px;min-width: 40px;margin-right: 10px;}
	.products-details-wrp .col-md-12.col-sm-12.col-lg-5{padding: 0;}
	.inner-footer{display: none;}
	.inner-bottom-nav{display: none;}
	.mobile-fix{position: fixed;bottom: 0;left: 0;right: 0;width: 100%;z-index: 9;border-radius:30px 30px 0 0;}
	.products-details-wrp .owl-carousel .owl-item img{min-height: 500px;object-fit: cover;}
	.products-details-wrp .pro-dt-img a i{bottom: 90px;}
	.cart-actionbar{border: none;}
	.cart-actionbar .btn-main{min-width: 120px;padding: 8px 30px;}
	.cart-hed ul li{font-size: 14px;font-weight: 500;}
	.cart-editbx ul li{min-height: auto;padding: 0;}
	.cart-editbx ul li.epclone{padding: 0;}
	.inner-cart-lst{height: 270px;}
	.cart-editbx ul li .form-control{width: 70px;}
	/* **** End product-cart **** */

	/* **** order **** */
	.btn_back{font-size: 14px;height: 40px;width: 40px;min-width: 40px;margin-right: 10px;}
	.order-product-wrp .order-block{min-height: auto;margin: 0 0 15px;}
	.order-product-wrp .order-box img{height: 50px;width: 50px;}
	.order-product-wrp .order-box h2{font-size: 14px;}
	.order-product-wrp .order-box p{font-size: 12px;}
	.order-block .col-md-8.col-sm-8{width: 60%;}
	.order-block .col-md-4.col-sm-4{width: 40%;}
	.order-product-wrp .log-btn{font-size: 12px;padding: 6px 12px; }
	.accordion .card .card-head h3{font-size: 24px;}
	.accordion .card .card-head h3:before{width: 54px;height: 33px;font-size: 16px;}

	.buket-wrp .buket-gallery{display: block;flex-flow:unset;}
	.buket-wrp .buket-grid-block{column-count:1;}

	.buket-bix-header .col-md-8.col-sm-8{width: 60%;}
	.buket-bix-header .col-md-4.col-sm-4{width: 40%;}
	.order-product-wrp .buket-wrp .buket-box .buket-info{display: block;}
	.order-product-wrp .buket-wrp .buket-box .buket-info img{height: 55px;width: 55px;margin:  0 0 10px;}
	.order-product-wrp .buket-wrp .buket-box .buket-info h2{font-size: 15px;}
	.order-product-wrp .buket-wrp .buket-box .buket-ic ul li a{height: 35px;width: 35px;font-size: 14px;}
	.order-product-wrp .buket-wrp .buket-box .buket-ic ul li{margin-right: 10px;}

	.buket-wrp .item{padding: 0;}
	.order-product-wrp .buket-wrp .buket-box .buket-bix-header{padding: 10px;}
	.order-product-wrp .buket-wrp .tbl-header ul li{font-size: 14px;word-break: break-all;padding: 10px 10px;}
	.order-product-wrp .buket-wrp .tbl-body h3{font-size: 17px;margin:15px 0 0 10px;}
	.order-product-wrp .buket-wrp .tbl-body ul li{font-size: 14px;padding: 10px 10px;}
	.total-box h3{font-size: 16px;}

	.place-fix{border-radius: 20px 20px 0 0;box-shadow: "0 -7px 6px rgb(0 0 0 / 7%)";}
	.place-fix .left-brd{display: none;}
	.place-fix ul{display: block;}
	.total-bx{display: flex;align-items: center;margin: 0;}
	.place-fix ul li:last-child{display: none;}
	.place-fix ul li{display: block;margin: 0;font-size: 16px;width:150px;}
	.place-fix ul li:nth-child(2){width: 100%;}
	.place-fix a{width: 120px;font-size: 13px;margin-left: auto;padding: 10px 14px;text-align: center;font-weight: 600;}
	.place-fix ul li span{display: inline-block;}
	.order-product-wrp .buket-wrp{padding: 30px 0 70px;}
	.order-product-wrp .plase-mobile-box{display: block;}
	/* **** ENd order **** */

	/* **** modal **** */
	.add-text-block h3{font-size: 26px;}
	.add-text-block h4{font-size: 22px;}
	.add-text-block{padding: 25px;}
	#add-text-popup .cart-actionbar .btn-main.calbtn{min-width:100px; padding: 8px 10px;}
	#add-text-popup .cart-actionbar .btn-main{min-width: 100px;padding: 8px 10px;}
	#delete-popup .cart-actionbar .btn-main.calbtn{min-width:100px; padding: 8px 10px;}
	#delete-popup .cart-actionbar .btn-main{min-width: 100px;padding: 8px 10px;}
	a.modal-mobile{display:block;}
	
	.mobile-num-bx{width: 100%;}
	.otp-wrp .mobile-num-bx{width: 100%;}
	.add-text-block p{font-size: 14px;}
	.otp-wrp .trash-btns .btn-main{padding: 10px 15px;}
	.back-step i{height: 40px;width: 40px;font-size: 14px;line-height: 40px;}

	.address-popup .modal-dialog{top: auto;transform: unset !important;margin: 30px auto;}
	.address-popup a.btn-main.canbtn{display: none;}
	.address-popup .alter-msg-bx h6.error-msg{margin: 0 0 15px;}
	.address-popup .add-row .row .btn-group{display: none;}
	.address-popup .add-row .row .btn-group .btn-main{display: block;width: 100%;font-size: 15px;font-weight: 500;text-align: center;}
	.address-popup button.order-placed-open{display: block;}
	.order-placed-wrp{border-radius: 25px 25px 0 0;}

	.verification-aside .modal-content{border-radius: 25px 25px 0 0;}
	.otp-aside .modal-content{border-radius: 25px 25px 0 0;}
	.verification-aside .modal-content{border-radius: 25px 25px 0 0;}
	/* **** End Modal **** */

	/* **** 404 **** */
	.center-pager img{width: 300px;}
	.error-txt h3{font-size: 26px;}
	.error-txt p{font-size: 16px;margin: 0 0 20px;}
	.error-txt{padding: 0 0 0 25px;width: 80%;margin: -100px auto 0;}
	.error-txt .btn-main{font-size: 15px;padding: 12px 40px;}
	/* **** End 404 **** */

	/* **** 500 **** */
	.refrewrp.error-txt{width: 100%;padding: 0 15px 0 20px;}
	.error-txt p br{display: none;}
	/* **** End 500 **** */


	.product-details-bottom .open-product{display: block;}
	.main-product-wrp .pro-dt-img a i{bottom: auto;left: 20px;top: 30px;}
	.product-details-bottom{
		width: 100%;
		float: none;
		position: fixed;
		left: 50%;
		transform: translate(-50%, 0);
		z-index: 99;
		bottom: 0;
		/* bottom: -300px; */
		border-radius: 40px 40px 0 0;
		-webkit-transition: all 0.3s linear;
		transition: all 0.3s linear;
	}
	.default-product-info-snackbar{
		height: 0;
		overflow: hidden;
		opacity: 0;
	}
	.pro-dt-hed h3{font-size: 20px;}
	.pro-dt-hed h4{font-size: 30px;}
	.quick-act-pro ul li{display: block;margin: 0 0 15px;}
	.main-product-wrp .quick-act-pro{top: -50px;}
	.main-product-wrp .pro-dt-hed .log-btn{padding: 10px 25px;font-size: 14px;line-height: 18px;margin: 10px 0 0;}
	.main-product-wrp .pro-lst-bx li{font-size: 15px;}
	.main-product-wrp .note-bx h4{font-size: 18px;margin: 15px 0 0;}
	.main-product-wrp .note-bx p{font-size: 14px;margin: 15px 0 0;line-height: 20px;}
	.main-product-wrp .quick-act-pro ul li a i{height: 40px;width: 40px;font-size: 15px;line-height: 40px;}
	.products-details-wrp{padding: 15px 0;}


	.pro-details-box.mobile-fix .cart-editbx{padding: 0 0;}
	/*.cart-editbx ul{padding: 0 25px;}
	.cart-editbx ul li h3{margin: 10px 0 0;}*/
	.cart-editbx ul li h3{margin: 10px 0 0;}
	.cart-hed ul li{width: 35%;}
	.mobilecart-aside .cart-editbx ul li{width: 35%;}
	.mobilecart-aside .cart-hed ul li:first-child{padding: 10px 10px 10px 18px;}

	.choice-bx{min-height: 180px;}

	.main-product-wrp .pro-details-box{padding:40px 25px 20px;width: 100%;border-radius: 40px 40px 0 0;}


	.figure img{height:215px;}
	
}